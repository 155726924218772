import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import history from "./Services/history";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import About from "./Component/StaticPages/aboutUs";
import Team from "./Component/StaticPages/Teams";
import reportWebVitals from "./reportWebVitals";
import FAQ from "./Component/StaticPages/faq";
import Contact from "./Component/StaticPages/Contact";
import Therapies from "./Component/StaticPages/TherapiesOffered/therapiesOffered";
import Service from "./Component/StaticPages/ServicesOffered/serviceOffered";
import Terms from "./Component/StaticPages/Terms/termsNconditions";
import Privacy from "./Component/StaticPages/Privacy/privacy";
import healthSpecialist from "./Component/StaticPages/HealthSpecialist/healthSpecialist";
import Carrer from "./Component/StaticPages/Carrers/carrer";
import BackPainAndNeckPain from "./Component/StaticPages/OurServices/backPainNeckPain";
import chestpt from "./Component/StaticPages/OurServices/chestpt";
import kneepain from "./Component/StaticPages/OurServices/kneepain";
import vertigopt from "./Component/StaticPages/OurServices/vertigopt";
import sportspt from "./Component/StaticPages/OurServices/sportspt";
import tenniselbow from "./Component/StaticPages/OurServices/tenniselbow";
import speechpt from "./Component/StaticPages/OurServices/speechpt";
import migrainept from "./Component/StaticPages/OurServices/migraine";
import physiotherapy from "./Component/StaticPages/OurServices/physiotherapy";
import parkinsonpt from "./Component/StaticPages/OurServices/parkinsonpt";
import pediatricpt from "./Component/StaticPages/OurServices/pediatric";
import lolai from './Component/StaticPages/TherapyCenter/lolai'
import Mohibullapur from './Component/StaticPages/TherapyCenter/Mohibuallpur'
import Jankipuram from './Component/StaticPages/TherapyCenter/Jankipuram'
import "./Component/Style/FontAwesome.css";
import { Provider } from "react-redux";
import configureStore from "./Core/ConfigureStore";

const store = configureStore();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Router history={history}>
      <React.StrictMode>
        <Route exact path="/" component={App} />
        <Route exact path="/about" component={About} />
        <Route exact path="/faq" component={FAQ} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/ourteams" component={Team} />
        <Route exact path="/therapy" component={Therapies} />
        <Route exact path="/service" component={Service} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/condition" component={healthSpecialist} />
        <Route exact path="/carrer" component={Carrer} />
        <Route
          exact
          path="/back-pain-neck-pain-physiotherapy-lucknow"
          component={BackPainAndNeckPain}
        />
        <Route
          exact
          path="/chest-physiotherapy-clinic-lucknow"
          component={chestpt}
        />
        <Route
          exact
          path="/migraine-physiotherapy-clinic-lucknow"
          component={migrainept}
        />
        <Route
          exact
          path="/best-parkinson-physiotherapy-lucknow"
          component={parkinsonpt}
        />
        <Route
          exact
          path="/pediatric-rehabilitation-clinic-lucknow"
          component={pediatricpt}
        />
        <Route
          exact
          path="/best-physiotherapy-lucknow"
          component={physiotherapy}
        />
        <Route exact path="/best-speech-therapy-lucknow" component={speechpt} />
        <Route
          exact
          path="/best-sports-physiotherapy-clinic-lucknow"
          component={sportspt}
        />
        <Route
          exact
          path="/tennis-elbow-pain-physiotherapy-lucknow"
          component={tenniselbow}
        />
        <Route
          exact
          path="/vertigo-physiotherapy-lucknow"
          component={vertigopt}
        />
        <Route
          exact
          path="/knee-pain-physiotherapy-lucknow"
          component={kneepain}
        />
        <Route
          exact
          path="/best-physiotherapy-clinic-lolai"
          component={lolai}
        />
        <Route
          exact
          path="/best-physiotherapy-clinic-mohibullapur"
          component={Mohibullapur}
        />
        <Route
          exact
          path="/best-physiotherapy-clinic-jankipuram"
          component={Jankipuram}
        />
      </React.StrictMode>
    </Router>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
