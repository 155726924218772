import Header from "../header";
import Footer from "../footer";
import { Pediatric } from "./OurServiceEnum/pediatricenum";
import "./styling/contentStyle.css";
import { Helmet } from "react-helmet";
import PediatricPhysioTherapy from "../../../Images/PediatricPhysioTherapy.jpg";
import Understanding from "../../../Images/understanding.jpg";
import Benefits from "../../../Images/Benefits.jpg";
import Importance from "../../../Images/Importance.jpg";
import Best from "../../../Images/Best.jpg";
export default () => {
  return (
    <div>
      <Helmet>
        <title>
          Best Pediatric Rehabilitation Clinic in Lucknow - ETSquares Health
        </title>
        <meta
          name="description"
          content="Looking for specialized pediatric rehabilitation services in Lucknow? ETSquares Health is the best pediatric rehabilitation clinic in Lucknow that offers expert care for children."
        ></meta>
        <link
          rel="canonical"
          href="https://etsquareshealth.com/pediatric-rehabilitation-clinic-lucknow"
        />
      </Helmet>
      <>
        <Header />
        <main>
          <div id="breadcrumb">
            <div className="container">
              <ul>
                <li>
                  <h1 style={{ color: "white" }}>Pediatric Rehabilitation</h1>
                </li>
              </ul>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">{Pediatric.BACK_PAIN_HEADER_TEXT}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: Pediatric.BACK_PAIN_CONTENT_TEXT,
                  }}
                ></p>
              </div>
              <div className="col-sm-6">
                <img
                  src={PediatricPhysioTherapy}
                  className="img-fluid"
                  alt="pediatric-physiotherapy"
                />
              </div>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <img
                  src={Understanding}
                  className="img-fluid"
                  alt="understanding-physiotherapy"
                />
              </div>
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">
                  {Pediatric.BACK_PAIN_UNDERSTANDING_HEADER_TEXT}
                </h2>
                <p
                  className="mb-5"
                  dangerouslySetInnerHTML={{
                    __html: Pediatric.BACK_PAIN_UNDERSTANDING_CONTENT_TEXT,
                  }}
                ></p>
              </div>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">
                  {Pediatric.COMPREHENSIVE_BACK_PAIN_HEADER_TEXT}
                </h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: Pediatric.COMPREHENSIVE_BACK_PAIN_CONTENT_TEXT,
                  }}
                ></p>
              </div>
              <div className="col-sm-6">
                <img
                  src={Importance}
                  className="img-fluid"
                  alt="importance-physiothearpy"
                />
              </div>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <img
                  src={Best}
                  className="img-fluid"
                  alt="best-physiotherapy"
                />
              </div>
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">{Pediatric.WHY_CHOOSE_US_HEADER_TEXT}</h2>
                <p>{Pediatric.WHY_CHOOSE_US_SUBHEADER_TEXT}</p>
                <p
                  className="mb-5"
                  dangerouslySetInnerHTML={{
                    __html: Pediatric.WHY_CHOOSE_US_CONTENT_TEXT,
                  }}
                ></p>
              </div>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">{Pediatric.FIRST_STEP_HEADER_TEXT}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: Pediatric.FIRST_STEP_CONTENT_TEXT,
                  }}
                ></p>
              </div>
              <div className="col-sm-6">
                <img
                  src={Benefits}
                  className="img-fluid"
                  alt="benefits-physiotherapy"
                />
              </div>
            </div>
          </div>

          {/* <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <img
                  src={FrequentlyAskedQuestion}
                  className="img-fluid"
                  alt="frequently-asked-questions"
                />
              </div>
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">{Pediatric.FAQS_HEADER_TEXT}</h2>
                <p
                  className="mb-5"
                  dangerouslySetInnerHTML={{
                    __html: Pediatric.FAQS_CONTENT_TEXT,
                  }}
                ></p>
              </div>
            </div>
          </div> */}
          <div id="breadcrumb">
            <div className="container">
              <ul>
                <li>
                  <h5 style={{ color: "white" }}>
                    {Pediatric.FAQS_HEADER_TEXT}
                  </h5>
                </li>
              </ul>
            </div>
          </div>
          <div style={{ marginLeft: "20px" }}>
            <p
              className="mb-5"
              dangerouslySetInnerHTML={{
                __html: Pediatric.FAQS_CONTENT_TEXT,
              }}
            ></p>
          </div>
        </main>
        <hr />
        <Footer />
      </>
    </div>
  );
};
