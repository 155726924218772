import Patient from '../../../Images/PatientImage.png'
export default () => {
    return (
        <section>
            <div className="row text-center">
                <div className="col-md-4 mb-5 mb-md-0">
                    <div className="d-flex justify-content-center mb-4">
                        <img src={Patient}
                            className="rounded-circle shadow-1-strong" width="150" height="150" />
                    </div>
                    <h5 className="mb-3">Suman Singh</h5>
                    <h6 className="text-primary mb-3"><i className='fa fa-heartbeat'></i>&nbsp;Patient</h6>
                    <p className="px-xl-3">
                        <i className="fa fa-quote-left pe-2"></i>Empathetic approach to pain. Relieved my chronic ankle
                        pain in 5 sessions. Very knowledgeable and honest with
                        the patients . Very happy with the service provided.
                        Totally recommended for musculoskeltal and Neurological
                        issues.
                    </p>
                    <ul className="list-unstyled d-flex justify-content-center mb-0">
                        <li>
                            <i className="fa fa-star fa-sm text-warning"></i>
                        </li>
                        <li>
                            <i className="fa fa-star fa-sm text-warning"></i>
                        </li>
                        <li>
                            <i className="fa fa-star fa-sm text-warning"></i>
                        </li>
                        <li>
                            <i className="fa fa-star fa-sm text-warning"></i>
                        </li>
                        <li>
                            <i className="fa fa-star fa-sm text-warning"></i>
                        </li>
                    </ul>
                </div>
                <div className="col-md-4 mb-5 mb-md-0">
                    <div className="d-flex justify-content-center mb-4">
                        <img src={Patient}
                            className="rounded-circle shadow-1-strong" width="150" height="150" />
                    </div>
                    <h5 className="mb-3">Ashutosh Singh</h5>
                    <h6 className="text-primary mb-3"><i className='fa fa-heartbeat'></i>&nbsp;Patient</h6>
                    <p className="px-xl-3">
                        <i className="fa fa-quote-left pe-2"></i>I Had Used ETsquares Health Service For The First Time
                        &amp; I Got Excellent Service. Very Good Nursing Home With
                        Affordable rates ,Doctors associated with them are very
                        caring. Providing best Service By Giving Personal
                        Attention To Each Patient.
                    </p>
                    <ul className="list-unstyled d-flex justify-content-center mb-0">
                        <li>
                            <i className="fa fa-star fa-sm text-warning"></i>
                        </li>
                        <li>
                            <i className="fa fa-star fa-sm text-warning"></i>
                        </li>
                        <li>
                            <i className="fa fa-star fa-sm text-warning"></i>
                        </li>
                        <li>
                            <i className="fa fa-star fa-sm text-warning"></i>
                        </li>
                        <li>
                            <i className="fa fa-star fa-sm text-warning"></i>
                        </li>
                    </ul>
                </div>
                <div className="col-md-4 mb-0">
                    <div className="d-flex justify-content-center mb-4">
                        <img src={Patient}
                            className="rounded-circle shadow-1-strong" width="150" height="150" />
                    </div>
                    <h5 className="mb-3">Vikram Singh</h5>
                    <h6 className="text-primary mb-3"><i className='fa fa-heartbeat'></i>&nbsp;Patient</h6>
                    <p className="px-xl-3">
                        <i className="fa fa-quote-left pe-2"></i>Prompt, Punctual and Communicative. Good doctor with
                        positive approach. Will not let down patient at any
                        cost. Always gives confidence to the patient. Patient
                        gets inspiration to be active whole day. I wish him all
                        the Best.
                    </p>
                    <ul className="list-unstyled d-flex justify-content-center mb-0">
                        <li>
                            <i className="fa fa-star fa-sm text-warning"></i>
                        </li>
                        <li>
                            <i className="fa fa-star fa-sm text-warning"></i>
                        </li>
                        <li>
                            <i className="fa fa-star fa-sm text-warning"></i>
                        </li>
                        <li>
                            <i className="fa fa-star fa-sm text-warning"></i>
                        </li>
                        <li>
                            <i className="fa fa-star fa-sm text-warning"></i>
                        </li>
                    </ul>
                </div>

                <div className="col-md-4 mb-5 mb-md-0">
                    <div className="d-flex justify-content-center mb-4">
                        <img src={Patient}
                            className="rounded-circle shadow-1-strong" width="150" height="150" />
                    </div>
                    <h5 className="mb-3">Kamini Singh</h5>
                    <h6 className="text-primary mb-3"><i className='fa fa-heartbeat'></i>&nbsp;Patient</h6>
                    <p className="px-xl-3">
                        <i className="fa fa-quote-left pe-2"></i>Very informative, caring and punctual. Suggested good
                        and easy breathing exercises for my dad having COPD.
                        Gave enough confidence and support to him. Have no
                        hesitation in recommending their services. One of the
                        most professional staff I have ever met.
                    </p>
                    <ul className="list-unstyled d-flex justify-content-center mb-0">
                        <li>
                            <i className="fa fa-star fa-sm text-warning"></i>
                        </li>
                        <li>
                            <i className="fa fa-star fa-sm text-warning"></i>
                        </li>
                        <li>
                            <i className="fa fa-star fa-sm text-warning"></i>
                        </li>
                        <li>
                            <i className="fa fa-star fa-sm text-warning"></i>
                        </li>
                        <li>
                            <i className="fa fa-star fa-sm text-warning"></i>
                        </li>
                    </ul>
                </div>
                <div className="col-md-4 mb-5 mb-md-0">
                    <div className="d-flex justify-content-center mb-4">
                        <img src={Patient}
                            className="rounded-circle shadow-1-strong" width="150" height="150" />
                    </div>
                    <h5 className="mb-3">Vipul Vishal Ranjan</h5>
                    <h6 className="text-primary mb-3"><i className='fa fa-heartbeat'></i>&nbsp;Patient</h6>
                    <p className="px-xl-3">
                        <i className="fa fa-quote-left pe-2"></i>One of the most professional physiotherapist I've met
                        through them. The Expert was a great listener, asks the
                        right questions, and is genuinely concerned about
                        patient's well being.Highly satisfied with the treatment
                        provided. Highly recommended. All the best for future.
                    </p>
                    <ul className="list-unstyled d-flex justify-content-center mb-0">
                        <li>
                            <i className="fa fa-star fa-sm text-warning"></i>
                        </li>
                        <li>
                            <i className="fa fa-star fa-sm text-warning"></i>
                        </li>
                        <li>
                            <i className="fa fa-star fa-sm text-warning"></i>
                        </li>
                        <li>
                            <i className="fa fa-star fa-sm text-warning"></i>
                        </li>
                        <li>
                            <i className="fa fa-star fa-sm text-warning"></i>
                        </li>
                    </ul>
                </div>
                <div className="col-md-4 mb-0">
                    <div className="d-flex justify-content-center mb-4">
                        <img src={Patient}
                            className="rounded-circle shadow-1-strong" width="150" height="150" />
                    </div>
                    <h5 className="mb-3">Ankit Singh</h5>
                    <h6 className="text-primary mb-3"><i className='fa fa-heartbeat'></i>&nbsp;Patient</h6>
                    <p className="px-xl-3">
                        <i className="fa fa-quote-left pe-2"></i>Great centre and amazing doctors. More than their
                        treatment it's their positivity that motivates you to
                        come around soon. Thoroughly skilled and truly confident
                        in approach .If you have an unbearable pain and low
                        confidence, this is the right platform.
                    </p>
                    <ul className="list-unstyled d-flex justify-content-center mb-0">
                        <li>
                            <i className="fa fa-star fa-sm text-warning"></i>
                        </li>
                        <li>
                            <i className="fa fa-star fa-sm text-warning"></i>
                        </li>
                        <li>
                            <i className="fa fa-star fa-sm text-warning"></i>
                        </li>
                        <li>
                            <i className="fa fa-star fa-sm text-warning"></i>
                        </li>
                        <li>
                            <i className="fa fa-star fa-sm text-warning"></i>
                        </li>
                    </ul>
                </div>

            </div>
        </section>
    )
}