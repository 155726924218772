import OwlCarousel from "react-owl-carousel";
import Blog from "./Blogs/blog";
import Clinic from "./HealthSpecialist/clinic";
import HealthSpecialist from "./HealthSpecialist/healthSpecialist";
import PatientConvenience from "./PatientConvenience/patientConvenience";
import WhatWeTreat from "./WhatWeTreat/whatWeTreat";
import Enquiry from "./Modal/enquiryModal";
import { ContentDetails } from "../Enums/contentEnum";
import { Helmet } from "react-helmet";
export default ({ isOpen, toggleModal }) => {
  return (
    <>
      <div>
        <Helmet>
          <title>Home - ETSquares Health</title>
          <meta
            name="description"
            content="Get relief from migraines with our specialized migraine physiotherapy clinic in Lucknow. Our skilled physiotherapists offer personalized treatments to help manage migraine symptoms."
          ></meta>
        </Helmet>
        <Enquiry isOpen={isOpen} toggleModal={toggleModal} />
        <main>
          <div className="hero_home version_3">
            <div className="content">
              <h3 className="fadeInUp animated">Best Physio Care in LUCKNOW</h3>
              <p className="fadeInUp animated">
                Lucknow's Fastest Growing Physiotherapy &amp; Chiropractor
                Service Provider. ETSquares Health Clinics &amp; Home Care
                Service Available in : Aliganj, Gomti Nagar, Gomti Nagar
                Extension, IIM Road, Kapoorthala, Mohibullapur
              </p>

              <div id="custom-search-input">
                {/* Slider For Available Location Starts */}
                <div className="row">
                  <div className="col-md-12 mt-4">
                    <OwlCarousel
                      items={3}
                      margin={8}
                      id="banner_owl"
                      autoplay={true}
                      className="owl-carousel owl-theme banner_owl_main_page owl-center owl-loaded"
                    >
                      <div className="owl-stage-outer">
                        <div
                          className="owl-stage"
                          style={{
                            transition: "all 0.25s ease 0s",
                            width: "3135px",
                            transform: "translate3d(-1072.5px, 0px, 0px)",
                          }}
                        >
                          <div
                            className="owl-item cloned"
                            style={{ width: "155px", marginRight: "10px" }}
                          >
                            <div className="item">
                              <a href="#">
                                <div className="title">
                                  <h3 className="fadeInUp animated">
                                    Gomti Nagar Extension
                                  </h3>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div
                            className="owl-item cloned"
                            style={{ width: "155px", marginRight: "10px" }}
                          >
                            <div className="item">
                              <a href="#">
                                <div className="title">
                                  <h3 className="fadeInUp animated">
                                    Gomti Nagar
                                  </h3>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div
                            className="owl-item cloned"
                            style={{ width: "155px", marginRight: "10px" }}
                          >
                            <div className="item">
                              <a href="#">
                                <div className="title">
                                  <h3 className="fadeInUp animated">
                                    Mohibullapur
                                  </h3>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div
                            className="owl-item cloned"
                            style={{ width: "155px", marginRight: "10px" }}
                          >
                            <div className="item">
                              <a href="#">
                                <div className="title">
                                  <h3 className="fadeInUp animated">
                                    Daliganj
                                  </h3>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div
                            className="owl-item"
                            style={{ width: "155px", marginRight: "10px" }}
                          >
                            <div className="item">
                              <a href="#">
                                <div className="title">
                                  <h3 className="fadeInUp animated">
                                    Tehri Pulia
                                  </h3>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div
                            className="owl-item"
                            style={{ width: "155px", marginRight: "10px" }}
                          >
                            <div className="item">
                              <a href="#">
                                <div className="title">
                                  <h3 className="fadeInUp animated">
                                    IIM Road
                                  </h3>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div
                            className="owl-item"
                            style={{ width: "155px", marginRight: "10px" }}
                          >
                            <div className="item">
                              <a href="#">
                                <div className="title">
                                  <h3 className="fadeInUp animated">
                                    Vikas Nagar
                                  </h3>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div
                            className="owl-item active"
                            style={{ width: "155px", marginRight: "10px" }}
                          >
                            <div className="item">
                              <a href="#">
                                <div className="title">
                                  <h3 className="fadeInUp animated">
                                    Puraniya
                                  </h3>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div
                            className="owl-item active center"
                            style={{ width: "155px", marginRight: "10px" }}
                          >
                            <div className="item">
                              <a href="#">
                                <div className="title">
                                  <h3 className="fadeInUp animated">Aliganj</h3>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div
                            className="owl-item active"
                            style={{ width: "155px", marginRight: "10px" }}
                          >
                            <div className="item">
                              <a href="#">
                                <div className="title">
                                  <h3 className="fadeInUp animated">Khadra</h3>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div
                            className="owl-item active"
                            style={{ width: "155px", marginRight: "10px" }}
                          >
                            <div className="item">
                              <a href="#">
                                <div className="title">
                                  <h3 className="fadeInUp animated">
                                    Nirala Nagar
                                  </h3>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div
                            className="owl-item"
                            style={{ width: "155px", marginRight: "10px" }}
                          >
                            <div className="item">
                              <a href="#">
                                <div className="title">
                                  <h3 className="fadeInUp animated">
                                    Indira Nagar
                                  </h3>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div
                            className="owl-item"
                            style={{ width: "155px", marginRight: "10px" }}
                          >
                            <div className="item">
                              <a href="#">
                                <div className="title">
                                  <h3 className="fadeInUp animated">Lekhraj</h3>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div
                            className="owl-item"
                            style={{ width: "155px", marginRight: "10px" }}
                          >
                            <div className="item">
                              <a href="#">
                                <div className="title">
                                  <h3 className="fadeInUp animated">
                                    Hazartganj
                                  </h3>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div
                            className="owl-item"
                            style={{ width: "155px", marginRight: "10px" }}
                          >
                            <div className="item">
                              <a href="#">
                                <div className="title">
                                  <h3 className="fadeInUp animated">
                                    Keshav Nagar
                                  </h3>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div
                            className="owl-item cloned"
                            style={{ width: "155px", marginRight: "10px" }}
                          >
                            <div className="item">
                              <a href="#">
                                <div className="title">
                                  <h3 className="fadeInUp animated">
                                    Jankipuram
                                  </h3>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div
                            className="owl-item cloned"
                            style={{ width: "155px", marginRight: "10px" }}
                          >
                            <div className="item">
                              <a href="#">
                                <div className="title">
                                  <h3 className="fadeInUp animated">
                                    Madiyaon
                                  </h3>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div
                            className="owl-item cloned"
                            style={{ width: "155px", marginRight: "10px" }}
                          >
                            <div className="item">
                              <a href="#">
                                <div className="title">
                                  <h3 className="fadeInUp animated">
                                    Kapoorthala
                                  </h3>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div
                            className="owl-item cloned"
                            style={{ width: "155px", marginRight: "10px" }}
                          >
                            <div className="item">
                              <a href="#">
                                <div className="title">
                                  <h3 className="fadeInUp animated">
                                    Polytechnique
                                  </h3>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="owl-controls">
                        <div className="owl-nav">
                          <div
                            className="owl-prev"
                            style={{ display: "none" }}
                          ></div>
                          <div
                            className="owl-next"
                            style={{ display: "none" }}
                          ></div>
                        </div>
                        <div
                          className="owl-dots"
                          style={{ display: "none" }}
                        ></div>
                      </div>
                    </OwlCarousel>
                  </div>
                </div>
                {/* Slider For Available Location Ends */}
              </div>
            </div>
          </div>

          <div className="bg_color_1">
            <div className="container margin_120_95 integrated_section">
              <div className="main_title">
                <h2>{ContentDetails.PATIENT_CONVENIENCE_HEADER_TEXT}</h2>
                <p>{ContentDetails.PATIENT_CONVENIENCE_CONTENT_TEXT}</p>
              </div>
              <div className="row add_bottom_30">
                <PatientConvenience />
              </div>
            </div>
          </div>

          <div className="container margin_120_95">
            <div className="main_title">
              <h2>{ContentDetails.HEALTH_SPECIALITY_HEADER_TEXT}</h2>
              <p>{ContentDetails.HEALTH_SPECILITY_CONTENT_TEXT}</p>
            </div>
            <div
              id="reccomended"
              className="owl-carousel owl-theme users_carousel owl-loaded owl-drag"
            >
              <HealthSpecialist />
            </div>
          </div>

          <div className="bg_color_1">
            <div className="container margin_120_95">
              <div className="main_title">
                <h2>{ContentDetails.ADVANCE_PHYSIO_THERAPY_HEADER_TEXT}</h2>
                <p>{ContentDetails.ADVANCE_PHYSIO_THERAPY_CONTENT_TEXT}</p>
              </div>
              <div
                id="reccomended"
                className="owl-carousel owl-theme users_carousel owl-loaded owl-drag"
              >
                <Clinic />
              </div>
            </div>
          </div>

          <div className="container margin_120_95 what_we_treat_section">
            <div className="main_title">
              <h1>{ContentDetails.WHAT_WE_TREAT_HEADER_TEXT}</h1>
              <br />
              <p>{ContentDetails.WHAT_WE_TREAT_CONTENT_TEXT}</p>
            </div>
            <div className="list_home">
              <WhatWeTreat />
            </div>
          </div>

          {/* <div className="bg_color_1">
          <div className="container margin_120_95">
            <div className="main_title">
              <h2>Latest Blogs </h2>
            </div>
            <Blog />
          </div>
        </div> */}
        </main>
        <hr />
      </div>
    </>
  );
};
