import Header from "../header";
import Footer from "../footer";
import { VertigoPT } from "./OurServiceEnum/vertigoptenum";
import "./styling/contentStyle.css";
import { Helmet } from "react-helmet";
import VertigoPhysioTherapy from "../../../Images/VertigoPhysioTherapy.jpg";
import Understanding from "../../../Images/understanding.jpg";
import FrequentlyAskedQuestion from "../../../Images/faq.jpg";
import Comprehensive from "../../../Images/Comprehensive.jpg";
import FirstStep from "../../../Images/FirstStep.jpg";
import HealthApproach from "../../../Images/HealthApproach.jpg";
import SupportMonitoring from "../../../Images/SupportMonitoring.jpg";
import EducationSelfManagement from "../../../Images/EducationSelfManagement.jpg";
import Rehabitilation from "../../../Images/Rehabitilation.jpg";
import TreatmentPlan from "../../../Images/TreatmentPlan.jpg";
export default () => {
  return (
    <div>
      <Helmet>
        <title>Best Vertigo Physiotherapy in Lucknow - ETSquares Health</title>
        <meta
          name="description"
          content="Get specialized vertigo physiotherapy in Lucknow. Our skilled physiotherapists provide tailored treatments to alleviate vertigo symptoms and restore your balance and confidence."
        ></meta>
        <link
          rel="canonical"
          href="https://etsquareshealth.com/vertigo-physiotherapy-lucknow"
        />
      </Helmet>
      <>
        <Header />
        <main>
          <div id="breadcrumb">
            <div className="container">
              <ul>
                <li>
                  <h1 style={{ color: "white" }}>Vertigo Physiotherapy</h1>
                </li>
              </ul>
            </div>
          </div>
          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">{VertigoPT.BACK_PAIN_HEADER_TEXT}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: VertigoPT.BACK_PAIN_CONTENT_TEXT,
                  }}
                ></p>
              </div>
              <div className="col-sm-6">
                <img
                  src={VertigoPhysioTherapy}
                  className="img-fluid"
                  alt="vertigo-physiotherapy"
                />
              </div>
            </div>
          </div>
          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <img
                  src={Understanding}
                  className="img-fluid"
                  alt="understanding-physiotherapy"
                />
              </div>
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">
                  {VertigoPT.BACK_PAIN_UNDERSTANDING_HEADER_TEXT}
                </h2>
                <p
                  className="mb-5"
                  dangerouslySetInnerHTML={{
                    __html: VertigoPT.BACK_PAIN_UNDERSTANDING_CONTENT_TEXT,
                  }}
                ></p>
              </div>
            </div>
          </div>
          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">
                  {VertigoPT.COMPREHENSIVE_BACK_PAIN_HEADER_TEXT}
                </h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: VertigoPT.COMPREHENSIVE_BACK_PAIN_CONTENT_TEXT,
                  }}
                ></p>
              </div>
              <div className="col-sm-6">
                <img
                  src={HealthApproach}
                  className="img-fluid"
                  alt="health-approach"
                />
              </div>
            </div>
          </div>
          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <img
                  src={Comprehensive}
                  className="img-fluid"
                  alt="comprehensive-physiotherapy"
                />
              </div>
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">{VertigoPT.WHY_CHOOSE_US_HEADER_TEXT}</h2>
                <p>{VertigoPT.WHY_CHOOSE_US_SUBHEADER_TEXT}</p>
                <p
                  className="mb-5"
                  dangerouslySetInnerHTML={{
                    __html: VertigoPT.WHY_CHOOSE_US_CONTENT_TEXT,
                  }}
                ></p>
              </div>
            </div>
          </div>
          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">{VertigoPT.FIRST_STEP_HEADER_TEXT}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: VertigoPT.FIRST_STEP_CONTENT_TEXT,
                  }}
                ></p>
              </div>
              <div className="col-sm-6">
                <img
                  src={TreatmentPlan}
                  className="img-fluid"
                  alt="treatment-plan"
                />
              </div>
            </div>
          </div>
          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <img
                  src={Rehabitilation}
                  className="img-fluid"
                  alt="rehabilitation-physiotherapy"
                />
              </div>
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">{VertigoPT.VESTIBULAR_HEADER_TEXT}</h2>
                <p
                  className="mb-5"
                  dangerouslySetInnerHTML={{
                    __html: VertigoPT.VESTIBULAR_CONTENT_TEXT,
                  }}
                ></p>
              </div>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">{VertigoPT.EDUCATIONAL_HEADER_TEXT}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: VertigoPT.EDUCATIONAL_CONTENT_TEXT,
                  }}
                ></p>
              </div>
              <div className="col-sm-6">
                <img
                  src={EducationSelfManagement}
                  className="img-fluid"
                  alt="education-self-management"
                />
              </div>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <img
                  src={SupportMonitoring}
                  className="img-fluid"
                  alt="support-monitoring"
                />
              </div>
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">{VertigoPT.MONITORING_HEADER_TEXT}</h2>
                <p
                  className="mb-5"
                  dangerouslySetInnerHTML={{
                    __html: VertigoPT.MONITORING_CONTENT_TEXT,
                  }}
                ></p>
              </div>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">{VertigoPT.RELIEF_HEADER_TEXT}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: VertigoPT.RELIEF_CONTENT_TEXT,
                  }}
                ></p>
              </div>
              <div className="col-sm-6">
                <img src={FirstStep} className="img-fluid" alt="first-step" />
              </div>
            </div>
          </div>

          {/* <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <img
                  src={FrequentlyAskedQuestion}
                  className="img-fluid"
                  alt="frequently-asked-questions"
                />
              </div>
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">{VertigoPT.FAQS_HEADER_TEXT}</h2>
                <p
                  className="mb-5"
                  dangerouslySetInnerHTML={{
                    __html: VertigoPT.FAQS_CONTENT_TEXT,
                  }}
                ></p>
              </div>
            </div>
          </div> */}
          <div id="breadcrumb">
            <div className="container">
              <ul>
                <li>
                  <h5 style={{ color: "white" }}>
                    {VertigoPT.FAQS_HEADER_TEXT}
                  </h5>
                </li>
              </ul>
            </div>
          </div>
          <div style={{ marginLeft: "20px" }}>
            <p
              className="mb-5"
              dangerouslySetInnerHTML={{
                __html: VertigoPT.FAQS_CONTENT_TEXT,
              }}
            ></p>
          </div>
        </main>
        <hr />
        <Footer />
      </>
    </div>
  );
};
