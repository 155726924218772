import chiropractic from "../../../Images/chiropractic.jpg";
import geriatric from "../../../Images/geriatric.jpg";
import needdle from "../../../Images/neuro.jpg";
import { ContentDetails } from "../../Enums/contentEnum";

export default () => {
  return (
    <>
      <div className="container">
        <div className="card-columns">
          <div className="card">
            <img
              className="card-img-top"
              src={chiropractic}
              alt="chiropractor"
            />
            <div className="card-body">
              <h4 className="card-title">
                {ContentDetails.TREATMENT_HEADER_TEXT}
              </h4>
              <p className="card-text">
                {ContentDetails.TREATMENT_CONTENT_TEXT}
              </p>
            </div>
          </div>
          <div className="card">
            <img className="card-img-top" src={geriatric} alt="geriatric" />
            <div className="card-body">
              <h4 className="card-title">
                {ContentDetails.CONNECT_ASSESS_HEADER_TEXT}
              </h4>
              <p className="card-text">
                {ContentDetails.CONNECT_ASSESS_CONTENT_TEXT}
              </p>
            </div>
          </div>
          <div className="card">
            <img className="card-img-top" src={needdle} alt="neurological" />
            <div className="card-body">
              <h4 className="card-title">
                {ContentDetails.POST_CARE_HEADER_TEXT}
              </h4>
              <p className="card-text">
                {ContentDetails.POST_CARE_CONTENT_TEXT}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
