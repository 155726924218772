import FaqTwo from "../Toggle/FaqTwo";
import { ContentDetails } from "../../Enums/contentEnum";
export default () => {
  const data = [
    {
      title: ContentDetails.PHYSICIAN_PRESCRIPTION_HEADING,
      description: ContentDetails.PHYSICIAN_PRESCRIPTION_TEXT,
    },
    {
      title: ContentDetails.TREATMENT_HOME_CARE_HEADING,
      description: ContentDetails.TREATMENT_HOME_CARE_TEXT,
    },
    {
      title: ContentDetails.ADVANCE_BOOKING_WALKIN_HEADING,
      description: ContentDetails.ADVANCE_BOOKING_WALKIN_TEXT,
    },
    {
      title: ContentDetails.CLINIC_NEAR_HEADING,
      description: ContentDetails.CLINIC_NEAR_TEXT,
    },
  ];
  return (
    <>
      {data.map((d) => {
        return <FaqTwo title={d.title} description={d.description} />;
      })}
    </>
  );
};
