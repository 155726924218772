import FaqFive from "../Toggle/FaqFive";
import { ContentDetails } from "../../Enums/contentEnum";
export default () => {
  const data = [
    {
      title: ContentDetails.CHARGES_PHYSIOTHERAPY_HEADING,
      description: ContentDetails.CHARGES_PHYSIOTHERAPY_TEXT,
    },
    {
      title: ContentDetails.PAYMENT_MODE_HEADING,
      description: ContentDetails.PAYMENT_MODE_TEXT,
    },
    {
      title: ContentDetails.BILL_REIMBURSEMENT_HEADING,
      description: ContentDetails.BILL_REIMBURSEMENT_TEXT,
    },
    {
      title: ContentDetails.CANCELLATION_POLICY_HEADING,
      description: ContentDetails.CANCELLATION_POLICY_TEXT,
    },
  ];
  return (
    <>
      {data.map((d) => {
        return <FaqFive title={d.title} description={d.description} />;
      })}
    </>
  );
};
