import "./enquiryModal.css";
import React, { useState, useEffect } from "react";
import ETTLogo from "../../../Images/EternalTechTherapy.png";
import GPlay from "../../../Images/callwhattsapp.png";
import Call from "../../../Images/call.png";
import Select from "react-select";

export default ({ isOpen, toggleModal }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [formData, setFormData] = useState({
    patientName: "",
    email: "",
    mobileNumber: "",
    remark: "",
    service: "",
    queryId: "",
    id: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setResponseMessage("");
    setIsValid(false);
  };

  const clearResponseMessage = () => {
    toggleModal();
    setResponseMessage("");
  };

  // Define the API endpoint URL
  const apiUrl = "https://myphysioapi20230210164513.azurewebsites.net";

  // Define your API key
  const apiKey = "ec4f7882-8bb6-40e7-8ae7-4d6c46cf738e";

  // Create headers with the API key
  const headers = {
    XApiKey: `${apiKey}`,
    "Content-Type": "application/json", // Adjust the content type as needed
    "Access-Control-Allow-Origin": "*", // To allow CORS
  };

  const handleSelectedInput = () => {
    setIsValid(false);
    setResponseMessage("");
  };

  useEffect(() => {
    // Use the fetch API to make the GET request
    fetch(`${apiUrl}/v1/MyPhysio/Products`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Transform the API data into the format expected by react-select
        const formattedOptions = data.map((item) => ({
          value: item.id, // Change this to match your API's value field
          label: item.name, // Change this to match your API's label field
        }));
        setOptions(formattedOptions);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (selectedOption !== null) {
      formData.service = selectedOption.label;
    }

    // Define regex patterns for validation
    const namePattern = /^[A-Za-z\s]+$/; // Only letters and spaces allowed
    //const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/; // Basic email validation
    const contact = /^\d{10}$/; // Only 10 digits allowed

    // Validation checks
    if (!namePattern.test(formData.patientName)) {
      setResponseMessage("Please enter a valid name.");
      setIsValid(true);
      return;
    }

    if (!contact.test(formData.mobileNumber)) {
      setResponseMessage("Please enter a valid 10 digit mobile number.");
      setIsValid(true);
      return;
    }

    if (formData.service.trim() === "") {
      setResponseMessage("Please select a symptom.");
      setIsValid(true);
      return;
    }

    setLoading(true);

    fetch(`${apiUrl}/v1/MyPhysio/PostWebQuery`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setLoading(false);
        setResponseMessage(data.message);
        setStatus(data.status);
      })
      .catch((error) => {
        setLoading(false);
        setResponseMessage(error.message);
      });

    formData.patientName = "";
    formData.email = "";
    formData.mobileNumber = "";
    formData.remark = "";
    formData.service = "";
    formData.queryId = "";
    formData.id = "";
    setFormData(formData);
    setSelectedOption(null);
  };

  return (
    <>
      {isOpen && (
        <>
          <div className="modal" style={{ display: "block" }}>
            <div className="modal-dialog modal-login">
              <div className="modal-content" style={{ marginTop: "140px" }}>
                <div className="modal-header">
                  <div className="avatar">
                    <img src={ETTLogo} alt="yoatthmes" />
                  </div>
                  <h4 className="modal-title">
                    Free Consultation <img src={GPlay} width="40" alt="logo" />
                    <img src={Call} width="33" alt="logo" /> 8881131781
                  </h4>

                  <button
                    type="button"
                    className="close"
                    onClick={() => clearResponseMessage()}
                    aria-hidden="true"
                  >
                    ×
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          onChange={handleInputChange}
                          name="patientName"
                          value={formData.patientName}
                          placeholder="Full Name.."
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          onChange={handleInputChange}
                          name="email"
                          value={formData.email}
                          placeholder="Email.."
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          onChange={handleInputChange}
                          name="mobileNumber"
                          value={formData.mobileNumber}
                          placeholder="Contact.."
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <Select
                          value={selectedOption}
                          onChange={(selected) => setSelectedOption(selected)}
                          onInputChange={() => handleSelectedInput()}
                          options={options}
                          isSearchable={true} // Enable the search input
                          placeholder="Search and select symptoms"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <textarea
                          className="form-control textarea-contact"
                          rows="5"
                          onChange={handleInputChange}
                          name="remark"
                          value={formData.remark}
                          placeholder="Please mention health condition.."
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-primary btn-lg btn-block login-btn"
                      onClick={handleSubmit}
                    >
                      Consult
                    </button>
                  </div>
                </div>
                <div className="modal-footer">
                  {loading ? (
                    <span className="text-warning">
                      Please wait we are posting your query....
                    </span>
                  ) : isValid ? (
                    <span className="text-danger">{responseMessage}</span>
                  ) : responseMessage && status ? (
                    <span className="text-success">{responseMessage}</span>
                  ) : (
                    <span className="text-danger">{responseMessage}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
          <div className="modal-backdrop fade in"></div>
        </>
      )}
    </>
  );
};
