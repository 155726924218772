import './blog.css'
import Yoga from '../../../Images/Yoga.jpg'
import Physio from '../../../Images/Cardio.jpg'
import Zumba from '../../../Images/Zumba.jpg'

export default () => {
    return (
        <section className="details-card">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="card-content">
                            <div className="card-img">
                                <img src={Yoga} alt="" />
                                <span><h4>Yoga</h4></span>
                            </div>
                            <div className="card-desc">
                                <h3>Yoga</h3>
                                <p>Yoga is a group of physical, mental, and spiritual practices or disciplines which originated in ancient India and aim to control (yoke) and still the mind, recognizing a detached witness-consciousness untouched by the mind  and mundane suffering .</p>
                                <a href="#" className="btn-card">Read</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card-content">
                            <div className="card-img">
                                <img src={Physio} alt="" />
                                <span><h4>Cardio</h4></span>
                            </div>
                            <div className="card-desc">
                                <h3>Cardio</h3>
                                <p>Cardio improves many aspects of health, including heart health, mental health, mood, sleep, weight regulation and metabolism. Actually, the heart becomes more efficient with every beat as it pumps oxygen-carrying blood, the lungs more effective in taking ..</p>
                                <a href="#" className="btn-card">Read</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card-content">
                            <div className="card-img">
                                <img src={Zumba} alt="" />
                                <span><h4>Zumba</h4></span>
                            </div>
                            <div className="card-desc">
                                <h3>Zumba</h3>
                                <p>Zumba classes combine latin and international music and dance to create a dynamic calorie-burning form of workout for people of all fitness levels and age groups. This effective fitness system features aerobic training that is a fusion of slow and fast...</p>
                                <a href="#" className="btn-card">Read</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}