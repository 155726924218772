import ETTLogo from "../../Images/EternalTechTherapy.png";
import { NavLink } from "react-router-dom";
import GPlay from "../../Images/Gplay.png";

export default () => {
  return (
    <footer>
      <div className="container margin_30_35 footerMobile showFooter">
        <div className="row ">

          <div className="col-lg-3 col-md-12">
            <p>
              <NavLink to="/">
                <img
                  src={ETTLogo}
                  data-retina="complete"
                  alt="logo"
                  width="120"
                  height="80"
                  className=" mobileIcon img-fluid"
                />
              </NavLink>
            </p>

          </div>

          <div className="col-lg-2 col-md-4">
            <h5>
              <strong>Our Presence</strong>
            </h5>
            <ul className="links">
              <li>
                <NavLink
                  to="/best-physiotherapy-clinic-jankipuram"
                  style={{ borderBottom: "1px solid #ededed" }}
                  className={
                    window.location.pathname ===
                      "/best-physiotherapy-clinic-jankipuram"
                      ? "active-class"
                      : ""
                  }
                >
                  Jankipuram
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/best-physiotherapy-clinic-mohibullapur"
                  style={{ borderBottom: "1px solid #ededed" }}
                  className={
                    window.location.pathname ===
                      "/best-physiotherapy-clinic-mohibullapur"
                      ? "active-class"
                      : ""
                  }
                >
                  Mohibullapur
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/best-physiotherapy-clinic-lolai"
                  style={{ borderBottom: "1px solid #ededed" }}
                  className={
                    window.location.pathname ===
                      "/best-physiotherapy-clinic-lolai"
                      ? "active-class"
                      : ""
                  }
                >
                  Lolai
                </NavLink>
              </li>

            </ul>
          </div>

          <div className="col-lg-4 col-md-4">
            <h5>
              <strong>Home Service</strong>
            </h5>
            <ul className="links">
              <li>
                Jankipuram | Vikas Nagar | Nirala Nagar
              </li>
              <li>
                Mohibullapur | Gol Market | Mahanagar
              </li>
              <li>
                Aliganj | Shara City | Shalimar Galten
              </li>
              <li>
                Gomti Nagar |Khurram Nagar | Kapoorthala
              </li>
              <li>
                Gomti Nagar Extension | Sushant Golf City
              </li>
              {/* <li>
                <NavLink to="/carrer">Carrers</NavLink>
              </li> */}
            </ul>
          </div>

          <div className="col-lg-3 col-md-4">
            <h5>Contact Us</h5>
            <ul className="contacts">
              <li>
                <a href="mailto:etsquareshealth@gmail.com">
                  <i className="fa fa-envelope"></i>etsquareshealth@gmail.com
                </a>
              </li>
              <li>
                <a
                  href="https://play.google.com/store/apps/details?id=etsquares.health.com"
                  target="_blank"
                >
                  <img src={GPlay} width="90" alt="logo" />
                </a>
              </li>
            </ul>
            <div className="follow_us">
              <h5>Follow us</h5>
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/profile.php?id=61556534245203&mibextid=ZbWKwL"
                    target="_blank"
                    rel="noopener"
                  >
                    <i className="fa fa-facebook-square"></i>
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank" rel="noopener">
                    <i className="fa fa-twitter-square"></i>
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank" rel="noopener">
                    <i className="fa fa-linkedin-square"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/etsquareshealthphysio?utm_source=qr&igsh=MWlqMGRibXU4MzEwbQ=="
                    target="_blank"
                    rel="noopener"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>


        <hr />
        <div className="row">
          <div className="col-md-8">
            <ul id="additional_links">
              <li>
                <NavLink to="/terms">Terms and conditions</NavLink>
              </li>
              <li>
                <NavLink to="/privacy">Privacy</NavLink>
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            <div id="copy" className="copyright ">
              © {new Date().getFullYear()} ETSquares Analytical Pvt. Ltd.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
