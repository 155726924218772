import Header from "./Component/StaticPages/header";
import Footer from "./Component/StaticPages/footer";
import Main from "./Component/StaticPages/homePage";
import "./Component/StaticPages/cornerButton.css";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import HttpsRedirect from "react-https-redirect";

function App() {
  const [isOpen, setIsOpen] = useState(true);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    switch (window.location.href) {
      case "https://www.etsquareshealth.com":
        window.location.href = "https://etsquareshealth.com/";
        break;
      case "https://www.etsquareshealth.com/":
        window.location.href = "https://etsquareshealth.com/";
        break;
        case "https://www.etsquareshealth.com/best-physiotherapy-clinic-lolai":
        window.location.href = "https://etsquareshealth.com/best-physiotherapy-clinic-lolai";
        break;
        case "https://www.etsquareshealth.com/best-physiotherapy-clinic-mohibullapur":
        window.location.href = "hhttps://etsquareshealth.com/best-physiotherapy-clinic-mohibullapur";
        break;
        case "https://www.etsquareshealth.com/best-physiotherapy-clinic-jankipuram":
        window.location.href = "https://etsquareshealth.com/best-physiotherapy-clinic-jankipuram";
        break;
    }
  }, []);
  return (
    <div>
      <HttpsRedirect>
        <Helmet>
          <link rel="canonical" href="https://etsquareshealth.com/" />
        </Helmet>
        <body
          style={{
            position: "relative",
            minHeight: "100%",
            top: "0px",
            overflow: "visible",
          }}
        >
          <div className="layer"></div>
          <Header />
          <Main isOpen={isOpen} toggleModal={toggleModal} />
          <Footer />

          <div className="App">
            <div className="floating-button" onClick={toggleModal}>
              <i className="fa fa-pencil" style={{ color: "#fff" }}></i>
            </div>
          </div>
        </body>
      </HttpsRedirect>
    </div>
  );
}

export default App;
