import Header from "../header";
import Footer from "../footer";
import { ChestPain } from "./OurServiceEnum/chestptEnum";
import "./styling/contentStyle.css";
import { Helmet } from "react-helmet";
import chestphysiotherapy from "../../../Images/chestphysiotherapy.jpg";
import Understanding from "../../../Images/understanding.jpg";
import Benefits from "../../../Images/Benefits.jpg";
import Importance from "../../../Images/Importance.jpg";
import Best from "../../../Images/Best.jpg";

export default () => {
  return (
    <div>
      <Helmet>
        <title>Chest Physiotherapy Clinic in Lucknow - ETSquares Health</title>
        <meta
          name="description"
          content="Find effective chest physiotherapy at our clinic in Lucknow. ETSquares Health provides specialized treatment to help manage respiratory conditions and improve lung function."
        ></meta>
        <link
          rel="canonical"
          href="https://etsquareshealth.com/chest-physiotherapy-clinic-lucknow"
        />
      </Helmet>
      <>
        <Header />
        <main>
          <div id="breadcrumb">
            <div className="container">
              <ul>
                <li>
                  <h1 style={{ color: "white" }}>Chest Physiotherapy</h1>
                </li>
              </ul>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">{ChestPain.BACK_PAIN_HEADER_TEXT}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: ChestPain.BACK_PAIN_CONTENT_TEXT,
                  }}
                ></p>
              </div>
              <div className="col-sm-6">
                <img
                  src={chestphysiotherapy}
                  className="img-fluid"
                  alt="chest-physiotherapy"
                />
              </div>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <img
                  src={Understanding}
                  className="img-fluid"
                  alt="understanding-physiotherapy"
                />
              </div>
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">
                  {ChestPain.BACK_PAIN_UNDERSTANDING_HEADER_TEXT}
                </h2>
                <p
                  className="mb-5"
                  dangerouslySetInnerHTML={{
                    __html: ChestPain.BACK_PAIN_UNDERSTANDING_CONTENT_TEXT,
                  }}
                ></p>
              </div>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">
                  {ChestPain.COMPREHENSIVE_BACK_PAIN_HEADER_TEXT}
                </h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: ChestPain.COMPREHENSIVE_BACK_PAIN_CONTENT_TEXT,
                  }}
                ></p>
              </div>
              <div className="col-sm-6">
                <img
                  src={Importance}
                  className="img-fluid"
                  alt="importance-physiotherapy"
                />
              </div>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <img
                  src={Best}
                  className="img-fluid"
                  alt="best-physiotherapy"
                />
              </div>
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">{ChestPain.FIRST_STEP_HEADER_TEXT}</h2>
                <p
                  className="mb-5"
                  dangerouslySetInnerHTML={{
                    __html: ChestPain.FIRST_STEP_CONTENT_TEXT,
                  }}
                ></p>
              </div>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">{ChestPain.BENEFITS_HEADER_TEXT}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: ChestPain.BENEFITS_CONTENT_TEXT,
                  }}
                ></p>
              </div>
              <div className="col-sm-6">
                <img
                  src={Benefits}
                  className="img-fluid"
                  alt="benefits-physiotherapy"
                />
              </div>
            </div>
          </div>

          <div id="breadcrumb">
            <div className="container">
              <ul>
                <li>
                  <h5 style={{ color: "white" }}>
                    {ChestPain.FAQS_HEADER_TEXT}
                  </h5>
                </li>
              </ul>
            </div>
          </div>
          <div style={{ marginLeft: "20px" }}>
            <p
              className="mb-5"
              dangerouslySetInnerHTML={{
                __html: ChestPain.FAQS_CONTENT_TEXT,
              }}
            ></p>
          </div>
        </main>
        <hr />
        <Footer />
      </>
    </div>
  );
};
