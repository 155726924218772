import { Fragment } from "react";
import Header from "./header";
import Footer from "./footer";
import { NavLink } from "react-router-dom";
import EttBanner from "../../Images/AboutUs.jpg";
import WhyToChoose from "./WhyToChoose/whyToChoose";
import CustomerReview from "./CustomerReview/customerReviews";
import { ContentDetails } from "../Enums/contentEnum";
import "../../Component/StaticPages/OurServices/styling/contentStyle.css";
import { Helmet } from "react-helmet";
export default () => {
  return (
    <div>
      <Helmet>
        <title>About Us - ETSquares Health</title>
        <meta name="description" content=""></meta>
      </Helmet>
      <Fragment>
        <Header />
        <main>
          <div id="breadcrumb">
            <div className="container">
              <ul>
                <li>About</li>
              </ul>
            </div>
          </div>

          <div className="bg_color_1">
            <div className="container margin_120_95">
              <div className="main_title">
                <h1>{ContentDetails.ABOUT_US_HEADER_TEXT}</h1>
                <p>{ContentDetails.ABOUT_US_SUBHEADER_TEXT}</p>
              </div>
              <div className="row justify-content-between">
                <div className="col-lg-6">
                  <figure className="add_bottom_30">
                    <img
                      src={EttBanner}
                      alt="ETT Banner"
                      className="img-fluid"
                    />
                  </figure>
                </div>
                <div
                  className="col-lg-5"
                  dangerouslySetInnerHTML={{
                    __html: ContentDetails.ABOUT_US_CONTENT_TEXT,
                  }}
                ></div>
              </div>
            </div>
          </div>

          <div className="testimonial">
            <div className="container margin_120_95">
              <div className="main_title">
                <h2>{ContentDetails.PATIENT_REVIEW_HEADER_TEXT}</h2>
                <p>{ContentDetails.PATIENT_REVIEW_SUBHEADER_TEXT}</p>
              </div>

              <div className="row">
                <CustomerReview />
              </div>
            </div>
          </div>

          <div className="bg_color_1">
            <div className="container margin_120_95">
              <div className="main_title">
                <h2>{ContentDetails.WHY_CHOOSE_ET_SQUARE_HEADER_TEXT}</h2>
                <p>{ContentDetails.WHY_CHOOSE_ET_SQUARE_SUBHEADER_TEXT}</p>
              </div>

              <div className="row">
                <WhyToChoose />
              </div>
            </div>
          </div>

          <div className="bg_color_1">
            <div className="container margin_120_95">
              <div className="main_title">
                <h2>{ContentDetails.OUR_MISSION_HEADER}</h2>
                <p>{ContentDetails.OUR_MISSION_SUBHEADER}</p>
              </div>

              <div
                className="row content-services"
                dangerouslySetInnerHTML={{
                  __html: ContentDetails.OUR_MISSION_TEXT,
                }}
              ></div>
            </div>
          </div>

          <div className="bg_color_1">
            <div className="container margin_120_95">
              <div className="main_title">
                <h2>{ContentDetails.OUR_VISION_HEADER}</h2>
                <p>{ContentDetails.OUR_VISION_SUBHEADER}</p>
              </div>

              <div
                className="row content-services"
                dangerouslySetInnerHTML={{
                  __html: ContentDetails.OUR_VISION_TEXT,
                }}
              ></div>
            </div>
          </div>
        </main>
        <hr />
        <Footer />
      </Fragment>
    </div>
  );
};
