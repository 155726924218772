import Header from "../header";
import Footer from "../footer";
import { KneePain } from "./OurServiceEnum/kneepainEnum";
import "./styling/contentStyle.css";
import { Helmet } from "react-helmet";
import KneePhysioTherapy from "../../../Images/KneePhysioTherapy.jpg";
import Understanding from "../../../Images/understanding.jpg";
import Benefits from "../../../Images/Benefits.jpg";
import KneePainVector from "../../../Images/KneePain.png";
import KneeRole from "../../../Images/KneeRole.jpg";
import Expectation from "../../../Images/Expectation.jpg";
export default () => {
  return (
    <div>
      <Helmet>
        <title>Best Knee Pain Physiotherapy in Lucknow</title>
        <meta
          name="description"
          content="Find relief from knee pain with our expert knee pain physiotherapy in Lucknow. ETSquares Health offers personalized treatments to reduce knee pain and improve quality of life."
        ></meta>
        <link
          rel="canonical"
          href="https://etsquareshealth.com/knee-pain-physiotherapy-lucknow"
        />
      </Helmet>
      <>
        <Header />
        <main>
          <div id="breadcrumb">
            <div className="container">
              <ul>
                <li>
                  <h1 style={{ color: "white" }}>Knee Pain Physiotherapy</h1>
                </li>
              </ul>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">{KneePain.BACK_PAIN_HEADER_TEXT}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: KneePain.BACK_PAIN_CONTENT_TEXT,
                  }}
                ></p>
              </div>
              <div className="col-sm-6">
                <img
                  src={KneePhysioTherapy}
                  className="img-fluid"
                  alt="knee-physiotherapy"
                />
              </div>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <img
                  src={Understanding}
                  className="img-fluid"
                  alt="understanding-physiotherapy"
                />
              </div>
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">
                  {KneePain.BACK_PAIN_UNDERSTANDING_HEADER_TEXT}
                </h2>
                <p
                  className="mb-5"
                  dangerouslySetInnerHTML={{
                    __html: KneePain.BACK_PAIN_UNDERSTANDING_CONTENT_TEXT,
                  }}
                ></p>
              </div>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">
                  {KneePain.COMPREHENSIVE_BACK_PAIN_HEADER_TEXT}
                </h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: KneePain.COMPREHENSIVE_BACK_PAIN_CONTENT_TEXT,
                  }}
                ></p>
              </div>
              <div className="col-sm-6">
                <img src={KneeRole} className="img-fluid" alt="knee-role" />
              </div>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <img
                  src={Benefits}
                  className="img-fluid"
                  alt="benefits-physiotherapy"
                />
              </div>
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">{KneePain.WHY_CHOOSE_US_HEADER_TEXT}</h2>
                <p>{KneePain.WHY_CHOOSE_US_SUBHEADER_TEXT}</p>
                <p
                  className="mb-5"
                  dangerouslySetInnerHTML={{
                    __html: KneePain.WHY_CHOOSE_US_CONTENT_TEXT,
                  }}
                ></p>
              </div>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">{KneePain.FIRST_STEP_HEADER_TEXT}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: KneePain.FIRST_STEP_CONTENT_TEXT,
                  }}
                ></p>
              </div>
              <div className="col-sm-6">
                <img
                  src={Expectation}
                  className="img-fluid"
                  alt="expectation-physiotherapy"
                />
              </div>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <img
                  src={KneePainVector}
                  className="img-fluid"
                  alt="faq chest physiotherapy"
                />
              </div>
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">{KneePain.CHARGE_HEADER_TEXT}</h2>
                <p
                  className="mb-5"
                  dangerouslySetInnerHTML={{
                    __html: KneePain.CHARGE_CONTENT_TEXT,
                  }}
                ></p>
              </div>
            </div>
          </div>

          {/* <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">{KneePain.FAQS_HEADER_TEXT}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: KneePain.FAQS_CONTENT_TEXT,
                  }}
                ></p>
              </div>
              <div className="col-sm-6">
                <img
                  src={FrequentlyAskedQuestion}
                  className="img-fluid"
                  alt="frequently-asked-questions"
                />
              </div>
            </div>
          </div> */}
          <div id="breadcrumb">
            <div className="container">
              <ul>
                <li>
                  <h5 style={{ color: "white" }}>
                    {KneePain.FAQS_HEADER_TEXT}
                  </h5>
                </li>
              </ul>
            </div>
          </div>
          <div style={{ marginLeft: "20px" }}>
            <p
              className="mb-5"
              dangerouslySetInnerHTML={{
                __html: KneePain.FAQS_CONTENT_TEXT,
              }}
            ></p>
          </div>
        </main>
        <hr />
        <Footer />
      </>
    </div>
  );
};
