import "./whyToChoose.css";
import { ContentDetails } from "../../Enums/contentEnum";
export default () => {
  return (
    <div className="box">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
            <div className="box-part text-center">
              <i className="fa fa-user-md fa-3x" aria-hidden="true"></i>

              <div className="title">
                <h4>{ContentDetails.FIVE_HUNDRED_HEADER}</h4>
              </div>

              <div className="text">
                <span>{ContentDetails.FIVE_HUNDERED_TEXT}</span>
              </div>

              <a href="#" className="learnMore">
                Learn More
              </a>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
            <div className="box-part text-center">
              <i className="fa fa-tasks fa-3x" aria-hidden="true"></i>

              <div className="title">
                <h4>{ContentDetails.INTEGRATED_APPROACH_HEADER}</h4>
              </div>

              <div className="text">
                <span>{ContentDetails.INTEGRATED_APPROACH_TEXT}</span>
              </div>

              <a href="#" className="learnMore">
                Learn More
              </a>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
            <div className="box-part text-center">
              <i className="fa fa-medkit fa-3x" aria-hidden="true"></i>

              <div className="title">
                <h4>{ContentDetails.TREATMENT_SCHEDULING_HEADER}</h4>
              </div>

              <div className="text">
                <span>{ContentDetails.TREATMENT_SCHEDULING_TEXT}</span>
              </div>

              <a href="#" className="learnMore">
                Learn More
              </a>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
            <div className="box-part text-center">
              <i
                className="fa fa-assistive-listening-systems fa-3x"
                aria-hidden="true"
              ></i>

              <div className="title">
                <h4>{ContentDetails.ALWAYS_LISTENING_HEADER}</h4>
              </div>

              <div className="text">
                <span>{ContentDetails.ALWAYS_LISTENING_TEXT}</span>
              </div>

              <a href="#" className="learnMore">
                Learn More
              </a>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
            <div className="box-part text-center">
              <i className="fa fa-credit-card fa-3x" aria-hidden="true"></i>

              <div className="title">
                <h4>{ContentDetails.PAYMENT_HEADING}</h4>
              </div>

              <div className="text">
                <span>{ContentDetails.PAYMENT_TEXT}</span>
              </div>

              <a href="#" className="learnMore">
                Learn More
              </a>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
            <div className="box-part text-center">
              <i className="fa fa-clock-o fa-3x" aria-hidden="true"></i>

              <div className="title">
                <h4>{ContentDetails.TWENTY_FOUR_SEVEN_HEADING}</h4>
              </div>

              <div className="text">
                <span>{ContentDetails.TWENTY_FOUR_SEVEN_TEXT}</span>
              </div>

              <a href="#" className="learnMore">
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
