import axios from "axios";
import env from "../Utils/env"

class Fetch {
    fetch = async (url, options, callBack) => {
        const apiUrl = 'https://myphysioapi20230210164513.azurewebsites.net';
        const fetchUrl = `${apiUrl}/${url}`;
        let response = {};
        let responseData;

        const reqOptions = {
            url: fetchUrl,
            ...options,
            validateStatus: (status) => {
                return true;
            }
        };

        try {
            response = await axios(reqOptions);
            responseData = response;
            if (callBack) {
                callBack(responseData)
            }
            return responseData;
        } catch (err) {
            return {
                status: 500,
                error: err
            }
        }
    }
}

export default new Fetch();