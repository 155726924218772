import { takeLatest, call, put } from "redux-saga/effects"
import * as types from '../Constant/Contact.Constant'
import Api from '../../Services/APIClass'

export function* postWebQuery(payload) {
    try {
        const response = yield call(Api.fetch, "v1/MyPhysio/PostWebQuery", {
            method: "POST",
            header: {
                'Access-Control-Allow-Origin': '*'
            },
            data: payload.payload,
            responseType: "json"
        });
        if (response) {
            yield put({ type: types.CONTACT_FORM_SUCCESS, response: response })
        }
        else {
            yield put({ type: types.CONTACT_FORM_FAILED })
        }
    }
    catch (err) {
        yield put({ type: types.CONTACT_FORM_FAILED })
    }
}

export default function* watchContactFormQuery() {
    yield takeLatest(types.CONTACT_FORM_COMPLETED, postWebQuery)
}