import Header from '../header'
import Footer from '../footer'
import './terms.css'

export default () => {
    return (
        <>
            <Header />
            <div id="breadcrumb">
                <div className="container">
                    <ul>
                        <li>Our Terms & Conditions</li>
                    </ul>
                </div>
            </div>
            <div className="container margin_60">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <div className="icon-left">
                                <i className="fa fa-thumbs-o-up fa-3x"></i>
                            </div>
                            <div className="text">
                                <h4> General</h4>
                                <p>ETSquares Health(hereinafter together be referred to as “Website/Application") is operated by ETSquares Analytical Private Limited (hereinafter to be referred as “Company” or “We” or “we” or “Our” or “our” or “Us” or “us”), a company duly incorporated under the provisions of the Companies Act, 2013m having its registered address at 1st Floor, A 308 Madhav Green City Near Amity Univeristy, Lucknow- 226010,Uttar Pradesh, India.
                                    Use of the Website is offered to You, subject to acceptance of all the terms, conditions and notices contained in these Terms including applicable policies which are incorporated herein by reference, along with any amendments / modifications made by Company at its sole discretion and posted on the Website, including by way of imposing an additional charge for access to or use of a service(s).

                                    For the purpose of these Terms of Use, wherever the context so requires "You" or "User" or “Your” shall mean any natural or legal person who has agreed to become a user on the Website as per the terms laid down below.

                                    Company shall not be required to notify You, of any changes made to the Terms and Conditions. The revised Terms shall be made available on the Website. Your use of the Website and the Services is subject to the most current version of the Terms made available on the Website, at the time of such use. You are requested to regularly visit the Website to view the most current Terms. It shall be Your responsibility to check the Terms periodically for changes. Company may require You to provide Your consent to the updated Terms in a specified manner prior to any further use of the Website and the Services, provided on the Website. If no such separate consent is sought, Your continued use of the Website, following changes to the Terms, will constitute Your express acceptance of those changes.</p>
                            </div> <br />
                            <div className="icon-left">
                                <i className="fa fa-codepen fa-3x"></i>
                            </div>
                            <div className="text">
                                <h4> Terms of Use</h4>
                                <p>1. By accessing the website or using it or subscribing to or using any of our services You agree that You have read, understood and are bound by the Terms. These Terms constitute a legal and binding contract between You on one part and the Company on the other Part as per the provisions of the Information Technology (Intermediaries guidelines) Rules, 2011 formulated under the Information Technology Act of 2000 and the Telemedicine Practice Guidelines (“TP Guidelines”).

                                    ‍2. By accessing the Website, You agree to give Your consent to avail Telemedicine Services as per the terms provided hereunder or any other terms which may be applicable to You from time to time.

                                    3. This electronic record is generated by a computer system and does not require any physical or digital signatures.

                                    4. If you do not want to be bound by the Terms, do not access, browse or in any way transact on the website, or avail any services.

                                    5. You also give Your consent to the Practitioner and the Company to refer You to healthcare service providers post Your consultation.</p>
                            </div> <br />
                            <div className="icon-left">
                                <i className="fa fa-ioxhost fa-3x"></i>
                            </div>
                            <div className="text">
                                <h4> Privacy Policy</h4>
                                <p>Kindly refer to our Privacy Policy at <b >https://etsquareshealth.com/privacy</b><br />
                                    By using the website or the subscribing to the services offered by us, you hereby consent that you have read and fully understood the said Privacy Policy. You further agree that the terms and contents of such Privacy Policy are acceptable to you.</p>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <div className="icon-left">
                                <i className="fa fa-money fa-3x"></i>
                            </div>
                            <div className="text">
                                <h4> Eligibility to use</h4>
                                <p>1.Use of the Website is available only to persons who can form legally binding contracts under Indian Contract Act, 1872. Thus, if you are a minor i.e. under the age of 18 years, you may use only with the involvement of a parent or guardian. The Company assumes no responsibility or liability for any misrepresentation of Your age.</p>
                            </div><br />
                            <div className="icon-left">
                                <i className="fa fa-taxi fa-3x"></i>
                            </div>
                            <div className="text">
                                <h4> User Account, Password and Security</h4>
                                <p>1. For the purposes of availing the Services through the Website, We may collect certain information from You including without limitations:
                                    a. Name;
                                    b. User ID;
                                    c. Email address;
                                    d. Address (including country and ZIP/ postal code);
                                    e. Gender;
                                    f. Age;
                                    g. Phone number;
                                    h. Password chosen by You;
                                    i. Billing Information including credit card, billing address and other payment information;
                                    j. Other details

                                    2. You shall ensure that the Information provided by You is true, complete, accurate and up-to-date.
                                    3. Use of another User's Information for availing the services offered by Company is expressly prohibited.
                                    4. If You provide any information that is untrue, inaccurate, not current or incomplete (or becomes untrue, inaccurate, not current or incomplete), or Company has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, Company has the right to suspend or terminate your account.
                                    5. We reserve the right to terminate Your registration and / or refuse to provide You with access to the Website if it is brought to Company’s notice or if it is discovered that You are under the age of 18 years.
                                    6. We reserve the right to refuse access to use the services offered at the Website to new Users or to terminate access granted to existing Users at any time without according any reasons for doing so and You shall have no right to object to the same.
                                    7. You will be responsible for maintaining the confidentiality of the Account Information and are fully responsible for all activities that occur under Your Account.
                                    8. If you are desirous of deleting or erasing Your Information including personally identifiable information from the Platform database, the Company reserves the right to process such erasure or deletion without notifying the RMP or any third-party, upon receiving Your request for exercising Your right to erasure with respect to that Information from the Platform database, and only after recording Your explicit consent. Once Your request is processed, such deletion shall be final, and the Company disclaims all responsibility for providing You any such deleted Information in the future. Further, the Company shall not be liable for the RMP’s failure to maintain a separate record of such Information as mandated by applicable laws.
                                    9. You agree to (a) immediately notify Company in writing about any compromise of Your Account or Account Information or any other breach of security, non-compliance of TP Guidelines, for which email notification should suffice and (b) ensure that You exit / log out from Your Account at the end of each session.
                                    10. Company cannot and will not be liable for any loss or damage arising from Your failure to comply with this Section. You may be held liable for losses incurred by Company or any other User of or visitor to the Website due to authorized or unauthorized use of Your Account as a result of Your failure in keeping Your Account Information secure and confidential or otherwise.
                                    11. The Website uses temporary cookies to store certain data (that is not sensitive personal data or information) that is used by us for the technical administration of the Website, research and development, and for user administration. In the course of serving advertisements or optimizing services to you, we may allow authorized third parties to place or recognize a unique cookie on Your browser. We do not store personally identifiable information in the cookies.</p>
                            </div><br />
                            <div className="icon-left">
                                <i className="fa fa-leaf fa-3x"></i>
                            </div>
                            <div className="text">
                                <h4> Limited User </h4>
                                <p>1. The website does not screen or censor the users who register on and access the website. You assume all the risk associated with dealing with other users with whom you come in contact through the website. You agree to use the website only for lawful purposes without infringing the rights or restricting the use of the website by any third party.
                                    2. You agree and undertake not to reverse engineer, modify, copy, distribute, transmit, display, download, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information or software obtained from the Website.
                                    3. You agree and undertake to use the Website and the Service only to post and upload messages and material that are proper.
                                    By way of illustrations, and not as a limitation, you agree and undertake that when using a Service, you will not:
                                    a. Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights of others;
                                    b. Publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material or information;
                                    c. Upload files that contain software or other material protected by intellectual property laws unless you own or control the rights thereto or have received all necessary consents; you own or control the rights thereto or have received all necessary consents;
                                    d. Upload or distribute files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of the Website or another's computer;
                                    e. Conduct or forward surveys, contests, pyramid schemes or chain letters;
                                    f. Download any file posted by another user of a Service that you know, or reasonably should know, cannot be legally distributed in such manner;
                                    g. Falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded;
                                    h. Violate any code of conduct or other guidelines, which may be applicable for or to any particular Service;
                                    i. Violate any applicable laws or regulations for the time being in force in or outside India; and
                                    j. Violate, abuse, unethically manipulate or exploit any of the terms and conditions of this Agreement or any other terms and conditions for the use of the Website contained elsewhere.</p>
                            </div>
                            <div className="text">
                                <h4> Telemedicine Services </h4>
                                <p>ETSquares provides resources which connects Users directly to Practitioners in real time, via live video conferencing, telephone and/or secure messaging for the diagnosis and treatment of patients over the Internet, as well as providing other types of administrative services and information (“Telemedicine Services”). All of the participating Practitioners are independent contractors and ETSquares Health itself provides no telemedicine services. The Practitioners are solely responsible for the Telemedicine Services.</p>
                            </div>
                            <div className="text">
                                <h4> Assumption of Risk </h4>
                                <p>The website and its contents are for communication purposes only. By using the website you hereby acknowledge and agree that any information posted on our website is not intended to be any kind of legal advice, medical advice or financial advice and no fiduciary relationship has been created between you and the company. You further agree that your purchase of any of the products on the website is at your own risk. We do not assume any responsibility or liability for any advice or any other information given on the website.</p>
                            </div>
                            <div className="text">
                                <h4> Indemnification </h4>
                                <p>You agree to defend and indemnify the company and any of its affiliates, employees, agents, directors and representatives and hold us harmless against any and all legal claims and demands, including but not limited to attorney’s fees, which may arise from your or relate to your use or misuse of the of the website or the services, your breach of these terms or your conduct or actions. You agree that the company shall, if it so desires, select its own legal counsel and participate in its own defense.</p>
                            </div>
                            <div className="text">
                                <h4> Governing Law and Dispute Resolution </h4>
                                <p>These Terms of Use and any contractual obligation between the Parties will be governed by the laws of India, with the exception of its conflict of law provisions. In case any litigation specifically permitted under these terms is initiated, the parties shall submit to the exclusive jurisdiction of the courts at Lucknow, Uttar Pradesh, India.</p>
                            </div>
                            <div className="text">
                                <h4> Modification and Variation </h4>
                                <p>The company may, at any time, change these terms without giving any notice to You. You hereby understand and agree that we have the right to modify these terms and any information and links contained herein. You further agree that any and all modifications to these terms shall be in full force and effect immediately upon being posted on the website and shall replace any prior version of these terms.</p>
                            </div>
                            <div className="text">
                                <h4> Report Abuse </h4>
                                <p>In the event You come across any abuse or violation of these Terms or if You become aware of any objectionable content on the Website, please report to Company’s customer support team.</p>
                            </div>
                            <div className="text">
                                <h4> Survival </h4>
                                <p>Even after termination, certain obligations mentioned under Covenants, Liability, Indemnity, Intellectual Property, Dispute Resolution will continue and survive termination.</p>
                            </div>
                            <div className="text">
                                <h4> Severability </h4>
                                <p>If any provision of these Terms of Use is deemed invalid, unlawful, void or for any other reason unenforceable, then that provision shall be deemed severable from these Terms of Use and shall not affect the validity and enforceability of any of the remaining provisions.</p>
                            </div>
                            <div className="text">
                                <h4> Waiver </h4>
                                <p>In the event that we fail to enforce any provisions of these terms, this shall not constitute a waiver of any enforcement of that provision or any other provision. No waiver of any provisions of these terms will be valid unless the same is in writing and signed by us.</p>
                            </div>
                            <div className="text">
                                <h4> Privacy Policy </h4>
                                <p>Company views protection of Your privacy as a very important principle. We store and process Your Account Information including any sensitive personal / financial information collected (as defined under the Information Technology Act, 2000), if any, on computers that may be protected by physical as well as reasonable technological security measures and procedures in accordance with Information Technology Act 2000 and Rules there under. Company’s current Privacy Policy is available at https://etsquareshealth.com/privacy. If You object to Your Information being transferred or used in this way, please do not use Website.

                                    Company will share / sell / transfer / license / covey some or all of your personal information with another business entity should we (or our assets) plan to merge with or are acquired by that business entity, or re-organization, amalgamation, restructuring of business or for any other reason whatsoever. Should such a transaction or situation occur, the other business entity or the new combined entity will be required to follow the privacy policy with respect to Your personal information. Once You provide Your information to us, You provide such information to Company and affiliates of Company and Company and its affiliate may use such information to provide You various services with respect to Your transaction whether such transaction are conducted on ETSquares or with third party merchant's or third party merchant's website.

                                    You accept the terms hereof and hereby consent to Us, sharing and/or processing Your information with third parties in an anonymized and aggregate manner.

                                    The User hereby consents, expresses and agrees that he/she has read and fully understands the Privacy Policy of Company in respect of the Website. You further consent that the terms and contents of such Privacy Policy are acceptable to You. (https://etsquareshealth.com/privacy)</p>
                            </div>
                            <div className="text">
                                <h4> Termination </h4>
                                <p>1. The Terms will continue to apply until terminated by either You or Company as set forth below. If You want to terminate Your agreement with Company, You may do so by
                                    (i) not accessing the Website; or
                                    (ii) closing Your accounts for all of the services that You use, where Company has made this option available to You.

                                    2. Company may, at any time, with or without notice, terminate the Terms (or portion thereof, such as any individual Additional Terms) with You if:
                                    - You breach any of the provisions of the Terms, the Privacy Policy or any other terms, conditions, or policies that may be applicable to You from time to time (or have acted in a manner that clearly shows that You do not intend to, or are unable to, comply with the same);
                                    - Company is required to do so by law (for example, where the provision of the services hereunder, to You is, or becomes, unlawful);
                                    - The provision of the services to You, by Company is, in Company's opinion, no longer commercially viable;
                                    - Company has elected to discontinue, with or without reason, access to the Website, the services (or any part thereof); or

                                    Company may also terminate or suspend all or a portion of Your account or access to the services with or without reason. Except as may be set forth in any Additional Terms applicable to a particular service, termination of Your Account may include: (i) removal of access to all offerings within the Website or with respect to the services; (ii) deletion of Your materials and Account Information, including Your personal information, log-in ID and password, and all related information, files and materials associated with or inside Your Account (or any part thereof); and (iii) barring of further use of the Services.

                                    You agree that all terminations shall be made in Company's sole discretion and that Company shall not be liable to You or any third party for any termination of Your Account (and accompanying deletion of Your Account Information), or Your access to the Website and the services offered thereunder.

                                    Notwithstanding the foregoing, these Terms will survive indefinitely unless and until Company chooses to terminate them.

                                    If You or Company terminates Your use of the Website, Company may delete any content or other materials relating to Your use of the Website and Company will have no liability to You or any third party for doing so.</p>
                            </div>
                            <div className="text">
                                <h4> Force Majeure</h4>
                                <p>1. You accept and acknowledge that we shall not be liable for any loss or damage caused to You as a result of delay or default or deficiency or failure in the Services as a result of any natural disasters, pandemic, fire, riots, civil disturbances, actions or decrees of governmental bodies, communication line failures (which are not caused due to the fault of ETSquares or the Third Party Service Providers), or any other delay or default or deficiency or failure which arises from causes beyond our reasonable control (“Force Majeure Event”).

                                    2. In the event of any Force Majeure Event arising, depending on whose performance has been impacted under the Terms of Use, the party shall immediately give notice to the Other Party(s) of the facts which constitute the Force Majeure Event.

                                    3. You explicitly give Your consent to the Company to use Your information in the event of any pandemic emergencies to cater towards the benefit of public at large. The Company may share Your information with regulatory bodies to fight any pandemic emergency.</p>
                            </div>

                            <div className="text">
                                <h4>Intellectual Property Rights</h4>
                                <p>1. The Website and the processes, and their selection and arrangement, including but not limited to all text, graphics, user interfaces, visual interfaces, sounds and music (if any), artwork and computer code (collectively, the "Owner Content") on the Website is owned and controlled by Company and the design, structure, selection, coordination, expression, look and feel and arrangement of such Owner Content is protected by copyright, patent and trademark laws, and various other intellectual property rights.

                                    2. The trademarks, logos and service marks displayed on the Website ("Marks") are the property of Company or their third parties or respective third parties. You are not permitted to use the Marks without the prior consent of Company, the relevant third parties that may own the Marks.

                                    3. Unless otherwise indicated or anything contained to the contrary or any proprietary material owned by a third party and so expressly mentioned, Company or its third-parties owns all intellectual property rights to and into the trademark of the Company and the Website, including, without limitation, any and all rights, title and interest in and to copyright, related rights, patents, utility models, designs, know-how, trade secrets and inventions (patent pending), goodwill, source code, meta tags, databases, text, content, graphics, icons, and hyperlinks.

                                    4. Except as expressly provided herein, You acknowledge and agree that You shall not copy, republish, post, display, translate, transmit, reproduce or distribute any Content through any medium without obtaining the express written permission from company or third party owner of such Content.</p>
                            </div>

                            <div className="text">
                                <h4> Definitions</h4>
                                <p>
                                    1. Telemedicine means delivery of health care services, where distance is a critical factor, by all health care professionals using information and communication technologies for the exchange of valid information for diagnosis, treatment and prevention of disease and injuries, in the interests of advancing the health of individuals and their communities. The primary modes for Telemedicine would include, inter alia, Video; Audio or Text (chat, images, messaging, emails, fax etc.).

                                    2. User/You means a patient, his/her representatives or affiliates, or any other person using Your registered account searching for Practitioners through the Website.

                                    3. Practitioner means a Registered Medical Practitioner (RMP) as per the provisions of the National Medical Commission Act, 2019 or a health care provider (whether an individual professional or an organization) or similar institution wishing to be registered, or already registered, on the Website, including designated and authorized associates of such practitioners or institutions.

                                    4. Caregiver means a family member or any other person authorized by the patient to represent the patient. If the age of the patient is 16 years or less, or if the patient is incapacitated (due to mental conditions like dementia or physical disability due to an accident), then the caregiver is deemed to be authorized to consult on behalf of the patient.

                                    You agree and acknowledge that You must comply with all rules and regulations governing Telemedicine, including but not limited to Telemedicine Practice Guidelines as issued by the Ministry of Health and Family Welfare (“TP Guidelines’). You further agree to keep Yourself updated with all amendments and/or issuance of any and all laws, rules, regulations, notifications, guidelines etc. governing Telemedicine. Under no event, You shall default to comply with the guidelines to be followed while carrying out Telemedicine services.

                                    By accessing the website or using it or subscribing to or using any of our services you agree that you have read, understood and are bound by the following, including without limitations,:


                                    1. Fees

                                    ETSquares Health has no role to play in determining the consultation fees of the Practitioner. The consultation fees are determined at the discretion of the Practitioner and ETSquares Health may charge a subscription fee and taxes whenever required as per the policies of the Company. User also understands that the fee charged by the Practitioner is for a single person only. In case the user attempts to obtain the consultation for more than one person, the Practitioner shall not address the consultation requirement for such additional person.

                                    2. Not For Emergency Use

                                    You acknowledge that the Telemedicine Services are for general use only and not suitable for emergent or urgent situations. IF YOU ARE FACING A MEDICAL EMERGENCY (either on your or on another person’s behalf), please CONTACT AN AMBULANCE SERVICE OR HOSPITAL DIRECTLY. You are advised to have a physical consultation/ in-person consultation.

                                    3. Date Storage

                                    You agree and acknowledge that the Company may keep record of any interactions and associated issues with the Practitioner including but not limited to the User’s health issues and/or the User’s experiences. The Company may keep the aforementioned data for the purpose of development of its services.

                                    4. Consent Requirement

                                    You acknowledge that the if the patient himself/ herself initiates the Telemedicine Services, then the consent for seeking such services is implied.If a care giver, health worker or practitioner initiates the Telemedicine Services on behalf of the patient, then explicit consent (in the form of written consent, verbal consent etc) is to be given by User prior to initiation of such services.

                                    ‍5. Caregiver Terms

                                    ‍In the event wherein

                                    a. Patient is present with the Caregiver during the consultation.

                                    b. Patient is not present with the Caregiver. This may be the case in the following:

                                    - Patient is a minor (aged 16 or less) or the patient is incapacitated, for example, in medical conditions like dementia or physical disability etc.
                                    - The care giver is deemed to be authorized to consult on behalf of the patient.
                                    - Caregiver has a formal authorization or a verified document establishing his relationship with the patient and/or has been verified by the patient in a previous in-person consult (explicit consult).

                                    However, we strictly advise the Patient and the Caregiver to be present during the consultation

                                    ‍6. Possible Risks

                                    The User understands that as with any medical procedure, there are risks associated with the use of Telemedicine Services as the Practitioners will not be conducting physical examination. You clearly understand and agree that You are aware of these limitations and risks which may include, without limitation, the following:

                                    a. Delays in consultation and evaluation or treatment may occur due to deficiencies or failures of technical equipment which may include poor audio/video quality.
                                    b. Failure of security protocols leading to a breach of privacy of personal information.
                                    c. Lack of access to complete medical history of the user may result in adverse drug interactions or allergic reactions or other negative outcomes.

                                    7. Your Warranties and Representations

                                    These warranties and representations shall also be applicable mutadis mutandis to a caregiver seeking services over the platform.

                                    The user acknowledges, understands and agrees to the following:

                                    a. You provide express consent to use your information for scheduling your appointment with the most suitable & available Practitioners based on a software and you also understand that your information shall be used for billing purposes.

                                    b. By using this platform, it is deemed that You have consented to receiving telephonic calls, SMSs and/or emails from us or any of our third-parties and the RMP. Such communications shall be sent to You on the telephone number and/or email id provided by You for the use of this Application which are subject to our Privacy Policy. Such communication by Us is for purposes that inter alia includes clarification calls, marketing calls and promotional calls.

                                    c. The prescription provided by the practitioner to you is based on the Telemedicine interaction and may vary when examined in person. Further, the prescription provided is as per the provisions of the TP Guidelines and do not contain medicines enlisted in the “Prohibited List” as elaborated upon in the TP Guidelines. Hence such prescription is not be taken as a final and conclusive solution.

                                    d. ETSquares Health is not liable for any information provided by the user that is untrue, inaccurate, not current or incomplete (or becomes untrue, inaccurate, not current or incomplete), or ETSquares Health has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, and ETSquares Health has the right to suspend the services to the user at its sole discretion.

                                    e. It is solely your responsibility to keep your correct mobile number and email ID updated at all times. ETSquares Health is not responsible for any loss or inconvenience caused due to your failure toupdate your contact details on the website/app.

                                    f. Notwithstanding anything contained herein, ETSquares Health is not in any manner responsible for any drug/medicines prescribed or the therapy prescribed by the Practitioner.

                                    g. Users shall not persuade any Practitioner to prescribe drugs such as:

                                    - Medication for Medical Termination of PregnancyHabit forming drugs i.e. drugs in Schedule X of Drugs and Cosmetics Rules, 1945
                                    - Narcotic or psychotropic drugs regulated by Narcotic Drugs and Psychotropic Substances Act, 1985
                                    - Drugs contained in the Prohibited List of TG Guidelines.

                                    h. In case the User wants to point out any discrepancies with the prescription or wishes to add, delete or modify anything in the said prescription, User should contact the Practitioner directly. ETSquares Health makes no representation for the information shared by the Practitioner.

                                    i. The Practitioner may exercise his/ her discretion in opting for a particular mode of tele-consultation depending on the nature of the ailment addressed by the Patient, and as per applicable laws. ETSquares Health has no role in determining the mode of tele-consultation with the Practitioner.

                                    j. The User acknowledges that Company is a mere facilitator and does not fall within the purview of the National Medical Commission Act, 2019, and the rules and regulations framed/ recognized thereunder.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <Footer />
        </>
    )
}