import Header from "../header";
import Footer from "../footer";
import Ortho from "../../../Images/Orthopaedic.png";
import Neuro from "../../../Images/neuro.png";
import Pediatrics from "../../../Images/pediatrics.png";
import Geriatric from "../../../Images/geriatric.png";
import "./therapiesOffered.css";
import { ContentDetails } from "../../Enums/contentEnum";
import { Helmet } from "react-helmet";
export default () => {
  return (
    <>
      <div>
        <Helmet>
          <title>Our Therapies - ETSquares Health</title>
          <meta name="description" content=""></meta>
        </Helmet>
        <Header />
        <div id="breadcrumb">
          <div className="container">
            <ul>
              <li>Therapies Offered In ETSquares</li>
            </ul>
          </div>
        </div>
        <div className="container margin_60">
          <div className="how-section1">
            <div className="row">
              <div className="col-md-6 how-img">
                <img
                  src={Ortho}
                  className="rounded-circle img-fluid"
                  alt="Ortho"
                />
              </div>
              <div className="col-md-6">
                <h4>{ContentDetails.ORTHOPAEDIC_THERAPY_HEADER_TEXT}</h4>
                <h4 className="subheading">
                  {ContentDetails.ORTHOPAEDIC_THERAPY_CONTENT_TEXT_BLUE}
                </h4>
                <p className="text-muted">
                  {ContentDetails.ORTHOPAEDIC_THERAPY_CONTENT_TEXT_WHITE}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <h4>{ContentDetails.NEURO_THERAPY_HEADER_TEXT}</h4>
                <h4 className="subheading">
                  {ContentDetails.NEURO_THERAPY_CONTENT_TEXT_BLUE}
                </h4>
                <p className="text-muted">
                  {ContentDetails.NEURO_THERAPY_CONTENT_TEXT_WHITE}
                </p>
              </div>
              <div className="col-md-6 how-img">
                <img
                  src={Neuro}
                  className="rounded-circle img-fluid"
                  alt="Neuro"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 how-img">
                <img
                  src={Pediatrics}
                  className="rounded-circle img-fluid"
                  alt="Pediatrics"
                />
              </div>
              <div className="col-md-6">
                <h4>{ContentDetails.PEDIATRIC_THERAPY_HEADER_TEXT}</h4>
                <h4 className="subheading">
                  {ContentDetails.PEDIATRIC_THERAPY_CONTENT_TEXT_BLUE}
                </h4>
                <p className="text-muted">
                  {ContentDetails.PEDIATRIC_THERAPY_CONTENT_TEXT_WHITE}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <h4>Geriatric Physiotherapy</h4>
                <h4 className="subheading">
                  Geriatric physiotherapy is the branch of physiotherapy that
                  relates to the care of older adults. A physiotherapist has a
                  key role in enabling older people to maximise their bodies
                  potential to enhance mobility and independence.
                </h4>
                <p className="text-muted">
                  Geriatric Rehabilitation (GR) aims to restore function or
                  enhance residual functional capability and improving the
                  quality of life in older people ie particularly those with
                  disabling impairments and/or frailty.Current rehabilitation
                  practice focuses on function and well-being, not exclusively
                  on disease. Rehabilitation of older adults patients can assist
                  in preserving functional independence and improving the
                  quality of life.
                </p>
              </div>
              <div className="col-md-6 how-img">
                <img
                  src={Geriatric}
                  className="rounded-circle img-fluid"
                  alt="Geriatric"
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <Footer />
      </div>
    </>
  );
};
