import "./healthSpecialist.css";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/custom-animations/cube-animation.css";
import sport from "../../../Images/sport.jpg";
import speech from "../../../Images/speechtherapy.jpg";
import occupational from "../../../Images/occupational.jpg";
export default () => {
  return (
    <div className="container mt-40 mb-30">
      <div className="row mt-30">
        <div className="col-md-4 col-sm-6">
          <div className="box21">
            <img className="pic-1" src={sport} alt="sports physiotherapy" />
            <div className="box-content">
              <h5 className="title" style={{ color: "white" }}>
                Sports Physiotherapy
              </h5>
              <p className="description">
                What is sports therapy? Also known as sports physiotherapy, it
                is an aspect of healthcare primarily dealing with preventing
                injury and rehabilitating patients and athletes who participate
                in sports activities.Sports therapy uses various techniques
                including manual therapy, heat/ice, stretching, electrotherapy,
                acupuncture, ultrasound, and vestibular therapy to maintain top
                function and fitness
              </p>
              <a className="read-more" href="#">
                read more
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-6">
          <div className="box21">
            <img className="pic-1" src={speech} alt="speech physiotherapy" />
            <div className="box-content">
              <h5 className="title" style={{ color: "white" }}>
                Speech Physiotherapy
              </h5>
              <p className="description">
                Speech and language therapists, also known as speech and
                language pathologists, are specialists in the area of
                communication, voice, speech, language, hearing, feeding,
                swallowing, as well as the social and cognitive aspects of
                communication.
              </p>
              <a className="read-more" href="#">
                read more
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-6">
          <div className="box21">
            <img
              className="pic-1"
              src={occupational}
              alt="Occupational physiotherapy"
            />
            <div className="box-content">
              <h5 className="title" style={{ color: "white" }}>
                Occupational Physiotherapy
              </h5>
              <p className="description">
                Occupational therapy (OT) is a healthcare profession. It
                involves the use of assessment and intervention to develop,
                recover, or maintain the meaningful activities, or occupations,
                of individuals, groups, or communities. The field of OT consists
                of health care practitioners trained and educated to improve
                mental and physical performance.
              </p>
              <a className="read-more" href="#">
                read more
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
