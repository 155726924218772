export const ContentDetails = {
  // Home Page Text Starts
  PATIENT_CONVENIENCE_HEADER_TEXT: "PATIENT CONVENIENCE",
  PATIENT_CONVENIENCE_CONTENT_TEXT:
    "We strive to make it as simple as possible for you to schedule a home visit or use the ETSquares Health service at any of our clinics.",
  TREATMENT_HEADER_TEXT: "TREATMENT",
  TREATMENT_CONTENT_TEXT:
    "The most efficient course of treatment in a clinic or at-home care environment that incorporates many therapies.",
  CONNECT_ASSESS_HEADER_TEXT: "CONNECT / ASSESS",
  CONNECT_ASSESS_CONTENT_TEXT:
    "We provide you with 24*7 support and match you with the best appropriate expert for an on-call or in-person assessment.",
  POST_CARE_HEADER_TEXT: "POST CARE",
  POST_CARE_CONTENT_TEXT:
    "For post-treatment care, an exercise regimen is recommended, and adherence is tracked via a mobile app.",
  HEALTH_SPECIALITY_HEADER_TEXT: "ETSQUARES HEALTHSPECIALITIES",
  HEALTH_SPECILITY_CONTENT_TEXT:
    "ETSquares Health offers care in a number of cities in a variety of specialisations. Confirm with us whether physiotherapists are available in the locations you are close to.",
  ADVANCE_PHYSIO_THERAPY_HEADER_TEXT: "ADVANCED PHYSIOTHERAPY CLINICS",
  ADVANCE_PHYSIO_THERAPY_CONTENT_TEXT:
    "To guarantee that you receive the best care possible, our clinics are furnished with the most up-to-date, certified technologies for healing and rehabilitation.",
  WHAT_WE_TREAT_HEADER_TEXT: "WHAT WE TREAT",
  WHAT_WE_TREAT_CONTENT_TEXT:
    "We offer physiotherapy treatments across Neuro/ Ortho/ Chiro/ Pediatrics/ Geriatrics/ Sports related issues covering a wide range of conditions & symptoms.",
  // Home Page Text Ends

  // Our Therapies Text Starts
  ORTHOPAEDIC_THERAPY_HEADER_TEXT: "Orthopaedic Physiotherapy",
  ORTHOPAEDIC_THERAPY_CONTENT_TEXT_BLUE:
    "Orthopaedic physiotherapy focuses primarily on treating conditions that affect the musculoskeletal system, encompassing joints, muscles, bones, ligaments, and tendons.",
  ORTHOPAEDIC_THERAPY_CONTENT_TEXT_WHITE:
    "Severe pain often accompanies injuries to these areas, making mobility challenging and hindering daily activities. Working with an experienced and qualified orthopaedic physiotherapist significantly enhances the likelihood of successful healing. They offer customized treatment programs and home exercises aimed at improving strength and range of motion, facilitating a smoother path to recovery.",
  NEURO_THERAPY_HEADER_TEXT: "Neurological Rehabilitation",
  NEURO_THERAPY_CONTENT_TEXT_BLUE:
    "Neurological physiotherapy encompasses the rehabilitation of individuals with neurological disorders.",
  NEURO_THERAPY_CONTENT_TEXT_WHITE:
    "Its goal is to enhance the quality of life for those affected by such conditions through physical interventions. Since each person with a neurological disorder exhibits unique characteristics, treatment is personalized to address specific conditions and symptoms.",
  PEDIATRIC_THERAPY_HEADER_TEXT: "Paediatric Physiotherapy",
  PEDIATRIC_THERAPY_CONTENT_TEXT_BLUE:
    "Occupational therapists specializing in paediatrics offer support and therapeutic services tailored to the paediatric population. Recognizing the distinctiveness of children compared to adults, these professionals possess a comprehensive understanding of typical child development and its correlation with bodily systems and functions.",
  PEDIATRIC_THERAPY_CONTENT_TEXT_WHITE:
    "The scope of physiotherapy treatment for children is extensive and evolves alongside their growth. As the child progresses, their needs change, necessitating adjustments to the treatment plan. To promote maximal independence in daily activities aligned with the child's educational, social, and cultural context, physiotherapists assess the child's home, school, or community environment and provide guidance on necessary modifications or interventions.",
  GERIATRIC_THERAPY_HEADER_TEXT: "Geriatric Physiotherapy",
  GERIATRIC_THERAPY_CONTENT_TEXT_BLUE:
    "Geriatric physiotherapy encompasses the specialized care provided to elderly individuals. Physiotherapists play a pivotal role in helping older adults optimize their physical abilities to promote mobility and independence.",
  GERIATRIC_THERAPY_CONTENT_TEXT_WHITE:
    "Geriatric rehabilitation (GR) seeks to either restore function or enhance existing functional capacity, thereby enhancing the quality of life for older individuals, especially those grappling with impairments or frailty. Contemporary rehabilitation approaches prioritize functionality and overall well-being, moving beyond solely addressing specific diseases. By focusing on preserving functional independence, rehabilitation interventions for elderly patients contribute significantly to enhancing their overall quality of life.",
  // Our Therpies Text Ends

  // FAQ's Text Starts
  WHAT_IS_PHYSIOTHERAPY_HEADING: "What is Physiotherapy?",
  WHAT_IS_PHYSIOTHERAPY_TEXT:
    "Physical therapy, or physiotherapy, is a medical intervention that aims to maximise a patient's function, mobility, and overall well-being. Physiotherapy promotes health and fitness, injury prevention, and physical rehabilitation. Physiotherapists establish a predetermined course of treatment to engage you in your own recovery. To help you heal faster, a physiotherapist could advise you to perform specific exercises on your own. Depending on the patient's condition, physiotherapists employ a variety of modalities in addition to manual therapy.",
  ISSUE_PHYSIO_CAN_TREAT_HEADING: "What issues can a physio treat?",
  ISSUE_PHYSIO_CAN_TREAT_TEXT:
    "Physiotherapists possess the expertise to address a wide array of issues, including sports injuries, musculoskeletal disorders, neurological conditions, paediatric concerns, and various other ailments based on their specialized training. Common issues that physiotherapists can effectively treat include:<ul><li style='padding:0.5rem'><strong>• Neck and back pain stemming from muscular and skeletal issues.</strong></li><li style='padding:0.5rem'><strong>• Conditions affecting bones, joints, muscles, and ligaments, such as arthritis.</strong></li><li style='padding:0.5rem'><strong>• Symptoms like fatigue, pain, swelling, stiffness, and decreased muscle strength.</strong></li><li style='padding:0.5rem'><strong>• Impaired mobility resulting from brain trauma (e.g., stroke) or spinal injuries, as well as conditions like Parkinson's disease and multiple sclerosis.</strong></li></ul>",
  PROCEDURE_PHYSIO_HEADING: "What procedures are used in Physiotherapy?",
  PROCEDURE_PHYSIO_TEXT:
    "Initially, a physiotherapist conducts a thorough assessment of the patient, laying the groundwork for the treatment process. Various approaches are employed in physiotherapy treatment, tailored to the individual needs and condition of each patient. Common procedures encompass:<ul><li style='padding:0.5rem'><strong>• Manual Exercises</strong></li><li style='padding:0.5rem'><strong>• Soft Tissue Mobilization</strong></li><li style='padding:0.5rem'><strong>• Electrotherapy</strong></li><li style='padding:0.5rem'><strong>• Heat Therapy</strong></li><li style='padding:0.5rem'><strong>• Sports Injury Taping</strong></li><li style='padding:0.5rem'><strong>• Ultrasound</strong></li></ul>",
  SPECIALIAZATION_THERAPY_HEADING:
    "Are there specializations in Physiotherapy?",
  SPECIALIAZATION_THERAPY_TEXT:
    "Musculoskeletal/orthopedic physiotherapy: Utilized for treating a range of conditions such as bone injuries, sprains, back pain, arthritis, strains, posture issues, as well as sports and workplace injuries.<br/><br/>Neurological Physiotherapy: Employed in the treatment of nervous system disorders, including strokes, spinal cord injuries, acquired brain injuries, multiple sclerosis, and Parkinson's disease.<br/><br/>Additionally, based on a physiotherapist's personal interests, experience, and certified training, they may specialize in one or more of the following areas: Paediatric Physiotherapy, Geriatric Physiotherapy, Vestibular Rehabilitation, Cardiovascular Physiotherapy, Pulmonary Physiotherapy, Sports Physiotherapy, Women’s Health Physiotherapy, Chiropractic Adjustments, Dry Needling Physiotherapy, Acupuncture Therapy, Cupping Therapy, and more.",
  RIGHT_PHYSIO_HEADING: "Who is the right physiotherapist for me?",
  RIGHT_PHYSIO_TEXT:
    "Choosing the appropriate physiotherapist is contingent upon the patient's condition and the required treatment. For instance, in cases like post-surgery rehabilitation, the patient would be matched with an orthopedic physiotherapist. Similarly, for more critical situations such as post-stroke recovery, not only would a neuro physiotherapist be designated, but careful consideration by ETSquares ensures the assignment of an experienced professional.<br/><br/>Consider another scenario: a child experiencing physical discomfort or injury would be directed to a paediatric physiotherapist. In instances where patients are uncertain about their specific condition—whether it's a muscle spasm or strain—the physiotherapist conducts an assessment to determine the appropriate treatment approach.",
  PHYSIO_ASSIGNED_HEADING:
    "How is Physio Assigned by ET Squares Physiotherapy?",
  PHYSIO_ASSIGNED_TEXT:
    "ET Squares Physiotherapy boasts a network of seasoned physiotherapists spanning various regions. In addition to considering patient condition and treatment needs, we also take your location into account. By combining these factors, we match you with the most suitable therapist for your treatment. Gender preference is another aspect we consider; typically, female physiotherapists are assigned to female patients unless otherwise specified. Furthermore, we prioritize assigning a nearby physiotherapist to ensure continuity in long-term treatments, preventing session gaps and ensuring timely care. ETSquares diligently ensures that each patient receives the optimal physiotherapist capable of effectively addressing their concerns.",
  EXERCISE_ON_OWN_HEADING: "Can I do exercises on my own?",
  EXERCISE_ON_OWN_TEXT:
    "Absolutely, if a physiotherapist recommends you to perform exercises independently, it's essential to follow through. Occasionally, exercises demonstrated during treatment sessions are intended for personal practice without direct supervision. The rationale behind encouraging independent exercise is to expedite recovery and alleviate pain. While some patients may consider consulting YouTube videos or online exercise programs, we strongly caution against this practice.",
  CHIROPACTOR_DIFFERENT_HEADING:
    "How is a Chiropractor different from a Physio?",
  CHIROPACTOR_DIFFERENT_TEXT:
    "Physiotherapists and chiropractors both assist patients dealing with pain or physical limitations, albeit employing different treatment approaches. Chiropractors primarily focus on aligning body parts, particularly the spine, through adjustments. Conversely, physiotherapists utilize exercise regimens and supportive equipment to enhance mobility and alleviate pain.",
  PHYSICIAN_PRESCRIPTION_HEADING: "Is a Physician's Prescription Needed?",
  PHYSICIAN_PRESCRIPTION_TEXT:
    "No, obtaining a prescription from a doctor for physiotherapy treatment is not obligatory. While doctors may recommend physiotherapy in certain instances, we ensure that our physiotherapists conduct their own assessments to diagnose the issue and initiate treatment accordingly. Our priority is to deliver the safest and most effective patient-centred care possible.",
  TREATMENT_HOME_CARE_HEADING:
    "Is Treatment at the clinic better than home care?",
  TREATMENT_HOME_CARE_TEXT:
    "In certain instances, when a patient's mobility is impaired, home-based physiotherapy becomes imperative. However, for others, the choice between home and clinic treatment lies with the patient. Nonetheless, certain treatments necessitate specialized equipment available solely at the clinic, optimizing results for patients who opt to visit. Additionally, the clinic offers the advantage of supervision by senior physiotherapists during treatment sessions. Nevertheless, if a patient prefers home treatment and requires only manual therapy or portable instruments, we offer home care physiotherapy services. For extended treatment durations, particularly if regular clinic visits pose a challenge, opting for home care services is advisable.",
  ADVANCE_BOOKING_WALKIN_HEADING: "Advance Booking vs Walkins?",
  ADVANCE_BOOKING_WALKIN_TEXT:
    "We highly recommend making advance bookings for appointments. With advance booking, ET Squares  can allocate a permanent time slot for the patient, allowing our physiotherapists to promptly initiate treatment upon arrival at your location. Conversely, visiting our clinic without a prior appointment may result in longer wait times, depending on the number of patients awaiting treatment. Therefore, we advise scheduling appointments in advance to ensure you receive treatment at your preferred time, minimizing any unnecessary waiting periods at the clinic. Additionally, if you plan to visit the clinic without a booking, it is advisable to contact us beforehand.",
  CLINIC_NEAR_HEADING: "Do you have any clinic near me?",
  CLINIC_NEAR_TEXT:
    "You can simply visit our website, input your location, and discover our nearby experts. If you encounter any challenges, feel free to share your location with us via WhatsApp, and we'll assist you in locating a nearby clinic, ensuring minimal time wastage and continuity of sessions. At ETSquares, we prioritize ensuring that patients receive treatment at their preferred times for utmost convenience.",
  PHYSIOTHERAPY_SERVICE_HEADING: "When to take home physiotherapy service?",
  PHYSIOTHERAPY_SERVICE_TEXT:
    "The choice between home-based physiotherapy and clinic visits hinges on the patient's condition and preferences. For instance, in cases like post-stroke rehabilitation involving balance-related issues or paralysis, home-based physiotherapy may be the most practical option. Conversely, if the condition is mild and the patient can easily attend sessions at a nearby clinic without interruption, clinic visits are suitable. Moreover, for patients with time constraints, opting for home-based physiotherapy services can be a convenient and time-saving alternative.",
  PHYSIOTHERAPY_NEAR_HOME_HEADING:
    "Is Physiotherapy at home available near my area?",
  PHYSIOTHERAPY_NEAR_HOME_TEXT:
    "We offer our physiotherapy services across multiple cities. To access our services in these areas, simply visit our website, input your location, and check the availability of physiotherapists. You can then schedule a session based on your preferred timings. For added convenience, you can share your location with us via WhatsApp, and we will help you find the most suitable physiotherapist or a nearby clinic.",
  DURATION_EACH_SESSION_HEADING: "What is the duration of each session?",
  DURATION_EACH_SESSION_TEXT:
    "Typically, our sessions last between 30 to 45 minutes, but they can be extended to 1 hour or 90 minutes if necessary for more comprehensive treatment. This extended duration is usually reserved for cases where patients are experiencing severe or multiple issues. Our experts determine the session duration following a thorough assessment of the patient's needs. It's important to note that the standard charges listed on our website apply to 30 to 45-minute sessions. For longer sessions, additional charges will apply.",
  SESSION_FREQUENCY_HEADING:
    "How many sessions & at what frequency are needed?",
  SESSION_FREQUENCY_TEXT:
    "The number of sessions required for a specific condition is not predetermined, as it is entirely contingent upon the patient's individual circumstances. For milder issues, our experts may recommend a few sessions, while for more critical cases such as neurological conditions, post-surgery rehabilitation, or elderly care, sessions typically extend over a longer period. Our physiotherapists will collaborate with you to devise a treatment plan, which may include an estimate of the number of sessions needed. Additionally, the session frequency will be discussed, with options for daily or alternate-day sessions, ensuring continuity in the treatment process without compromising effectiveness.",
  CHARGES_PHYSIOTHERAPY_HEADING: "What are the charges for physiotherapy?",
  CHARGES_PHYSIOTHERAPY_TEXT:
    "ETSquares  strives to offer cost-effective treatment options, although charges may differ between cities. Home physiotherapy services are priced slightly higher than clinic rates. For extended treatment durations, ETSquares,  offers discounted package prices. There are no additional charges for the use of modalities during sessions. To ascertain the home and clinic rates in your city, you can visit our website for further details.",
  PAYMENT_MODE_HEADING: "What payment modes are accepted?",
  PAYMENT_MODE_TEXT:
    "ETSquares  accommodates various payment methods, ensuring flexibility for patients. Accepted forms of payment include cash, credit or debit cards, bank transfers, as well as online payment services like Paytm, Google Pay, and others.",
  BILL_REIMBURSEMENT_HEADING:
    "Will you provide Bills for insurance reimbursement?",
  BILL_REIMBURSEMENT_TEXT:
    "ETSquares  issues authentic treatment bills to patients, facilitating hassle-free reimbursement claims from insurance providers. Consistency is maintained throughout the treatment course, with sessions conducted by the same physiotherapist. However, patients retain the option to request a change of physiotherapist if necessary.",
  CANCELLATION_POLICY_HEADING: "What is your cancellation & refund policy?",
  CANCELLATION_POLICY_TEXT:
    "There are no cancellation fees for services not utilized; patients will receive a full refund if they have prepaid for a session. However, if a patient experiences service-related concerns, the treatment quality will be thoroughly addressed and improvements will be made for subsequent sessions. Please note that refunds will not be issued for previously completed sessions.",
  //FAQ's Text Ends

  // About Us Text Starts
  ABOUT_US_HEADER_TEXT: "ETSquares Health",
  ABOUT_US_SUBHEADER_TEXT: "Integrated Physio Care for Active Healing!",
  ABOUT_US_CONTENT_TEXT:
    "<p>ETSquares Health stands as India's rapidly expanding Delivery Network for Physiotherapy & Chiropractic services. Our dedication lies in enhancing accessibility to premium physio care via state-of-the-art clinics and proficient practitioners. Employing an integrated, multi-therapy approach, we prioritize patient needs, ensuring the delivery of exemplary, patient-centric care and superior outcomes.</p><p>Our services cater to a diverse clientele, extending treatments both within clinic premises and within the comfort of patients' homes. Our clinics are meticulously outfitted with cutting-edge equipment and foster clean, dynamic, and uplifting environments conducive to optimal healing. In home care, our established protocols guarantee the provision of top-tier treatment coupled with exceptional patient care.</p><p>Rather than merely engaging in transactions with patients, we are steadfast in cultivating partnerships throughout their healing journey. We accompany patients from the onset to the culmination of their healing process, offering a comprehensive program encompassing a variety of therapies and modalities. Even after treatment concludes, we provide ongoing support and guidance to mitigate the risk of recurring injuries or pain. The satisfaction evident on our patients' faces propels our continued dedication and commitment.</p>",
  PATIENT_REVIEW_HEADER_TEXT: "OUR PATIENT LEFT A REVIEW FOR US",
  PATIENT_REVIEW_SUBHEADER_TEXT:
    "Happiness on our patient’s faces is what drives us!",
  WHY_CHOOSE_ET_SQUARE_HEADER_TEXT: "WHY CHOOSE ETSQUARES HEALTH?",
  WHY_CHOOSE_ET_SQUARE_SUBHEADER_TEXT:
    "Treatment that blends Competence with Compassion!",
  OUR_MISSION_HEADER: "OUR MISSION",
  OUR_MISSION_SUBHEADER: "Happy Health Keeps Mind Healthy!",
  OUR_MISSION_TEXT:
    "<p>Our mission encompasses the delivery of cutting-edge orthopedic, sports medicine, and rehabilitation services through a team of passionate doctors and courteous staff. Together, we strive towards a singular vision: to achieve unparalleled excellence in orthopedic care.</p><p>We are dedicated to comprehending the needs of those we have the privilege to serve, earning and upholding the trust and respect of our patients by surpassing their expectations. Our commitment lies in continual improvement at every level, ensuring that we consistently exceed standards.</p>",
  OUR_VISION_HEADER: "OUR VISION",
  OUR_VISION_SUBHEADER: "Avoid Medicine!",
  OUR_VISION_TEXT:
    "<p>The inception of our sports healthcare facility marked the early stages of a vision aimed at offering premier arthroscopic surgery and comprehensive medical amenities for athletes. Our goal is to ensure that everyone has access to top-notch orthopedic and advanced sports medicine care. We aspire to foster the careers of our esteemed athletes, enabling them to achieve excellence in their respective sports at both national and international levels.</p><p>Our vision extends beyond traditional athletes to encompass a broader spectrum, including industry professionals, parents, young enthusiasts, occasional participants, weekend warriors, and active senior citizens. We aim to continually expand this inclusive definition of athleticism, enriching the lives of individuals from diverse backgrounds and pursuits.</p>",
  // About Us Text Ends

  // Why Choose Text Starts
  FIVE_HUNDRED_HEADER: "+ 500 Practitioners",
  FIVE_HUNDERED_TEXT:
    "Over 500 physiotherapists and chiropractors have joined ET Squares' robust network via a defined empanelment criteria.",
  INTEGRATED_APPROACH_HEADER: "Integrated Approach",
  INTEGRATED_APPROACH_TEXT:
    "We are able to treat patients based on their goals and take a comprehensive approach to their needs thanks to our integrated/multitherapy strategy.",
  TREATMENT_SCHEDULING_HEADER: "Treatment scheduling",
  TREATMENT_SCHEDULING_TEXT:
    "The home visit is verified in 30 minutes and the clinic appointment is confirmed instantly. We make every effort not to substitute a physiotherapist for continuing care.",
  ALWAYS_LISTENING_HEADER: "Always Listening",
  ALWAYS_LISTENING_TEXT:
    "Ongoing evaluation of the effectiveness of the treatment plan, including calls for counselling. proactive resolution of grievances to guarantee a simple route to your recuperation.",
  PAYMENT_HEADING: "Payments",
  PAYMENT_TEXT:
    "Clear pricing combined with affordable packages for extended medical care. Option to pay with a credit card, cash, or online and receive billing that is insurance reimbursement friendly.",
  TWENTY_FOUR_SEVEN_HEADING: "24*7 Support",
  TWENTY_FOUR_SEVEN_TEXT:
    "Only a text message away is help. For any question or worry regarding your upcoming visit for continuous physical therapy, you can get 24 hour help on WhatsApp.",
  // Why Choose Text End
};
