import * as types from '../Constant/Contact.Constant'
import { contactInitialState } from './contactInitialState'

const initialState = {
    contacts: [],
    contact: { ...contactInitialState }
}

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case types.CONTACT_FORM_COMPLETED: {
            const contact = action.payload;
            return {
                ...state,
                contact: contact
            }
        }

        case types.CONTACT_FORM_SUCCESS: {
            const contacts = action.response;
            return {
                ...state,
                contacts: contacts
            }
        }

        default: {
            return { ...state }
        }
    }
}

export default reducer;